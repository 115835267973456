<template>
    <v-form ref="groupForm" v-model="valid">
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert = false"
            :info="info" />
        <v-card class="pb-10 pt-2" :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
            <v-card-title class="headline">
                {{ $store.getters.getTextMap().write_in_logbook }} <v-spacer></v-spacer><v-checkbox :dark="$store.getters.getColorPalette().isDark" v-model="showSKUID" :label="$store.getters.getTextMap().show_sku_id" ></v-checkbox>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col>
                            <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                            <v-menu v-if="!loading" v-model="menu" :close-on-content-click="false" :nudge-right="40"
                                transition="scale-transition" offset-y min-width="290px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="form.date" :label="$store.getters.getTextMap().production_start_date" prepend-icon="mdi-calendar" readonly
                                        outlined dense v-bind="attrs" v-on="on" ></v-text-field>
                                </template>
                                <v-date-picker v-model="form.date" @input="menu = false"></v-date-picker>
                            </v-menu>
                        </v-col>


                        <v-col>
                            <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                            <v-menu v-if="!loading" ref="menufrom" v-model="menu3" :close-on-content-click="false"
                                :nudge-right="40" :return-value.sync="form.fromTime" transition="scale-transition"
                                offset-y max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="form.fromTime" :label="$store.getters.getTextMap().production_start_time"
                                        prepend-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" v-on="on"
                                         outlined dense></v-text-field>
                                </template>
                                <v-time-picker v-if="menu3" v-model="form.fromTime" full-width format="24hr"
                                    @click:minute="$refs.menufrom.save(form.fromTime)"></v-time-picker>
                            </v-menu>
                        </v-col>

                        <v-col>
                            <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                            <v-menu v-if="!loading" v-model="menu1" :close-on-content-click="false" :nudge-right="40"
                                transition="scale-transition" offset-y min-width="290px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="form.to_date" :label="$store.getters.getTextMap().production_date" prepend-icon="mdi-calendar"
                                        readonly v-bind="attrs" v-on="on" :rules="objectRules" outlined
                                        dense></v-text-field>
                                </template>
                                <v-date-picker v-model="form.to_date" @input="menu1 = false"></v-date-picker>
                            </v-menu>
                        </v-col>

                        <v-col>
                            <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                            <v-menu v-if="!loading" ref="menuto" v-model="menu2" :close-on-content-click="false"
                                :nudge-right="40" :return-value.sync="form.toTime" transition="scale-transition"
                                offset-y max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="form.toTime" label="Production Time"
                                        prepend-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" v-on="on"
                                        :rules="objectRules" outlined dense></v-text-field>
                                </template>
                                <v-time-picker v-if="menu2" v-model="form.toTime" full-width format="24hr"
                                    @click:minute="$refs.menuto.save(form.toTime)"></v-time-picker>
                            </v-menu>
                        </v-col>

                    </v-row>

                    <v-row>
                        <v-col>
                            <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                            <v-text-field outlined dense v-if="!loading" v-model="form.quantity" label="Quantity"
                                :rules="numberRules"></v-text-field>
                        </v-col>
                        <v-col>
                            <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                        <v-autocomplete
                            v-if="!loading"
                            v-model="form.sku_id"
                            :items="skuOptions"
                            :label="$store.getters.getTextMap().sku"
                            outlined   
                            dense       
                            item-text="label"
                            item-value="value"
                            required
                            prepend-icon="mdi-palette-swatch-variant"
                            :dark="$store.getters.getColorPalette().isDark"
                            :rules="objectRules"
                        ></v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col v-for="field in metaItems" :key="field.label">
                            <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                            <v-select v-if="!loading && field.type == 'list'" v-model="metaData[field.label]"
                                :items="getListOptions(field.field)" :label="field.label" dense filled item-text="label"
                                item-value="value" required :rules="objectRules"
                                :menu-props="{ dark: $store.getters.getColorPalette().isDark }"></v-select>
                            <v-text-field v-else :loading="loading" outlined dense v-model="metaData[field.label]"
                                :counter="10" :rules="[rules.required]" :label="field.label" required></v-text-field>
                        </v-col>
                    </v-row>


                </v-container>




            </v-card-text>
            <div>
                <div class="d-flex justify-center">
                    <v-btn @click="onSubmit" :dark="$store.getters.getColorPalette().isDark"
                        :color="$store.getters.getColorPalette().submitbtnColor" v-if="!loading" class="mx-2">
                        {{$store.getters.getTextMap().submit}}<v-icon small class="pl-2">mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn @click="onReset" :dark="$store.getters.getColorPalette().isDark"
                        :color="$store.getters.getColorPalette().cancelbtnColor" class="mx-2">
                        {{$store.getters.getTextMap().clear}}<v-icon small class="pl-2">mdi-eraser</v-icon>
                    </v-btn>
                </div>
            </div>
        </v-card>
    </v-form>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import InfoAlert from '@/components/InfoAlert'
import FormRules from '@/utillities/FormRules'
export default {
    name: 'WriteBatchTracingLog',
    props: {
        'batchLog': {
            type: Object,
            required: true
        }

    },
    components: {
        InfoAlert
    },
    mounted() {
        this.init()

    },
    data() {
        return {
            loading: false,
            info: '',
            showDismissibleAlert: false,
            showSKUID:false,
            valid: false,
            menu: null,
            menu1: null,
            menu2: null,
            menu3: null,
            metaData: {},
            meta: {},
            form: {
                fromTime: null,
                toTime: null,
                date: null,
            },
            rules: FormRules.rules,
            objectRules: [
                FormRules.rules.required,
            ],
            floatRules: [
                // v => !!v || 'Required',
                v => (!v || !isNaN(Number(v))) || 'It should be a number',
                v => (!v || (v && v > 0)) || 'It should be more than zero'
            ],
            logRules: [
                v => !!v || 'Required',
                v => /\S+/.test(v) || 'Required',
                v => (v && v.length <= 100 && v.length > 0) || 'Log must be less than 100 characters',
            ],
            numberRules: [
                v => !!v || 'Required',
                v => /^\d+$/.test(v) || 'Input must contain only numbers'
            ],
            nameRules: [
                v => !!v || 'Name is required',
                v => /\S+/.test(v) || 'Name is required',
                v => (v && v.length <= 40 && v.length > 0) || 'Name must be less than 40 characters',
                v => isNaN(parseFloat(v)) || 'Input cannot be a number' // New rule for checking if the input is not a number
            ],


        }
    },
    computed: {
        skuOptions(){
            let op=[]
            let g=this.$store.state.skus
            for(let i of g){
                if(this.showSKUID){
                    op.push( {value:i.sku_id, label: i.sku_id })

                }else{

                    op.push( {value:i.sku_id, label: i.sku_name })
                }
            }
          return op
        },

        fromTimeStamp() {
            if (this.form.date) {

                let dt = this.form.date.toString()
                if (this.form.fromTime) {
                    dt += ' ' + this.form.fromTime.toString()
                    return Number(moment(dt, 'YYYY-MM-DD HH:mm').format("X"));
                }
                return Number(moment(dt, 'YYYY-MM-DD').format("X"));

            } else {
                return null
            }
        },
        toTimeStamp() {
            if (this.form.to_date) {
                let dt = this.form.to_date.toString()
                if (this.form.toTime) {
                    dt += ' ' + this.form.toTime.toString()
                    return Number(moment(dt, 'YYYY-MM-DD HH:mm').format("X"));
                }
                return Number(moment(dt, 'YYYY-MM-DD').format("X"));

            } else if (this.form.date) {

                let dt = this.form.date.toString()
                if (this.form.toTime) {
                    dt += ' ' + this.form.toTime.toString()
                    return Number(moment(dt, 'YYYY-MM-DD HH:mm').format("X"));
                }
                return Number(moment(dt, 'YYYY-MM-DD').format("X"));

            } else {
                return null
            }
        },

        metaItems() {
            if (this.meta) {
                return Object.values(this.meta)
            }
            return []
        }


    },
    methods: {
        init() {
            if (this.batchLog) {
                this.meta = Object.assign({}, this.batchLog.meta)
            }
        },
        getListOptions(list_id) {
            let op = []
            let lst = this.$store.getters.getListDataById(list_id)
            for (let i of lst) {
                op.push({
                    label: i.tag,
                    value: i.value
                })
            }
            return op
        },
        onSubmit() {
            this.$refs.groupForm.validate()
            if (this.valid) {
                //console.log("entered")
                this.loading = true
                //evt.preventDefault()
                this.form.log_id = this.batchLog.log_id
                this.form.from_timestamp = this.fromTimeStamp
                this.form.to_timestamp = this.toTimeStamp
                this.form.meta_data = this.metaData


                let temp = {
                    production_start: this.fromTimeStamp,
                    production_time: this.toTimeStamp,
                log_id: this.batchLog.log_id,
                meta:this.metaData,
                quantity:this.form.quantity,
                sku_id:this.form.sku_id,
                code:this.form.code,
                location_id:this.batchLog.location_id,
                status_text:this.batchLog.default_status_text
                }

                let d = Object.assign({}, temp)

                // console.log(d);

                axios.post(this.$store.state.api + 'writeBatchProductionLog', d, {
                    headers: {
                        Authorization: 'Bearer ' + this.$store.state.jwt
                    }
                })
                    .then(response => {
                        if (response.data.status === 'success') {
                            //this.$store.commit('addParameter',d)
                            //this.$store.dispatch('refreshParameters')
                            //console.log("Parameter added "+d)

                            this.loading = false
                            this.info = "Success. Batch ID: "+response.data.data.batch_id
                            this.showDismissibleAlert = true
                            //this.dialog=false
                            this.$refs.groupForm.reset()
                            this.form={}
                            this.$emit('success')
                            this.$emit('close')

                        } else {
                            //console.log(response.data.msg)
                            this.info = response.data.msg;
                            //this.info = d;
                            this.showDismissibleAlert = true;
                            //console.log(response.data.msg)
                            this.loading = false
                        }
                    })
                    .catch(err => {
                        console.log(err)
                        this.info = err
                        this.loading = false
                        this.showDismissibleAlert = true
                    });
                //this.$store.dispatch('addDevice', d)

            }
        },
        onReset() {
            this.$refs.groupForm.reset()
            this.$emit('close')
        }
    },
    watch: {
        "batchLog.meta": {
            handler() {

                this.init()
            },
            deep: true
        },
    }
}
</script>
<style scoped>
table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;

}
</style>