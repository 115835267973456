<template>
    <v-container class="" fluid>
        <span v-if="$store.state.dynamicLogs && $store.state.dynamicLogs.length>0">
            <v-subheader :style="{ color: $store.getters.getColorPalette().accentCode }">{{$store.getters.getTextMap().dynamic_logs}}</v-subheader><v-divider :color="$store.getters.getColorPalette().accentCode"></v-divider> 
        <v-row>
        <v-col  cols="4" v-bind:key="dynamicLog.log_id" v-for="dynamicLog in $store.state.dynamicLogs">
            <DynamicLogger :dynamicLog="dynamicLog"/>
        </v-col>
        </v-row>
        </span>
    </v-container>
</template>

<script>
import DynamicLogger from '@/components/items/loggers/DynamicLogger'
export default {
    name:'dynamicLoggerList',
    data(){
        return {

        }
    },
    components:{
        DynamicLogger
    },
    computed:{
   
    },
    methods:{
    }
}
</script>

<style scoped>

</style>