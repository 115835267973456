<template>
    <v-lazy
            v-model="isActive"
            :options="{
              threshold: .5
            }"
            
            transition="fade-transition"
          >
        <div :color="$store.getters.getColorPalette().accentCode">
            <v-card :dark="$store.getters.getColorPalette().isDark"
            flat 
             @click="dialog = true" class="px-2 py-0 mx-1 my-2 " 
          :color="$store.getters.getColorPalette().loggerColor"
          >
            
            <v-card-title > {{capacityLog.name}} <v-spacer></v-spacer><v-icon color="orange lighten-1"> mdi-clipboard-list-outline</v-icon>
            </v-card-title>
            </v-card>
            <v-dialog
          v-model="dialog"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
        <v-card :dark="$store.getters.getColorPalette().isDark"  :color="$store.getters.getColorPalette().backgroundColorCode">
        <v-toolbar
            :dark="$store.getters.getColorPalette().isDark" 
             :color="$store.getters.getColorPalette().foregroundColorCode"
            >
              <v-btn
                icon
            :dark="$store.getters.getColorPalette().isDark" 
                @click="dialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>{{capacityLog.name }} Logs</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn
                 :dark="$store.getters.getColorPalette().isDark" 
                  text
                  @click="dialog = false"
                >
                {{$store.getters.getTextMap().close}}
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>
           <CapacityLogDetails :capacityLog="capacityLog">
            <template v-slot:top>

              <WriteCapacityLog :capacityLog="capacityLog" />
            </template>
          </CapacityLogDetails>
        </v-card>
            </v-dialog>
        </div>
    </v-lazy>
    </template>
    <script>
    
    import CapacityLogDetails from '@/components/panels/loggers/CapacityLogDetails'
    import WriteCapacityLog from '@/components/crud_components/loggers/WriteCapacityLog'
    
    export default {
        name:'CapacityLogger',
        props:['capacityLog'],
        components:{
            CapacityLogDetails,
            WriteCapacityLog
        },
        mounted() {
        
        },
        data(){
            return {
                isActive:false,
                dialog:false,
                
            }
        },
        computed:{
            
        },
        methods: {
            deleteCapacityLog(){
    
            }, 
        },
    }
    </script>
    
    
    