<template>
    <div class="text-center" :dark="$store.getters.getColorPalette().isDark">
        <v-btn 
            :background-color="$store.getters.getColorPalette().background1ColorCode"
            :color="$store.getters.getColorPalette().accentCode" :dark="$store.getters.getColorPalette().isDark" small
            outlined text @click="dialog = !dialog">
            {{ $store.getters.getTextMap().create_shift_based_machine_usage_log }}
            <v-icon 
                right 
                :dark="$store.getters.getColorPalette().isDark"
                :color="$store.getters.getColorPalette().btnborderColorCode">mdi-clipboard-text-outline
            </v-icon>
      </v-btn>
      <v-dialog v-model="dialog" fullscreen hide-overlay transition="scroll-x-transition">
        <v-card :dark="$store.getters.getColorPalette().isDark">
          <v-toolbar 
            :dark="$store.getters.getColorPalette().isDark"
            :color="$store.getters.getColorPalette().foregroundColorCode">
            <v-btn 
                icon 
                :dark="$store.getters.getColorPalette().isDark" 
                @click="dialog = false"
            ><v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title class="headline">{{$store.getters.getTextMap().shift_based_machine_usage_log }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn :dark="$store.getters.getColorPalette().isDark" text @click="closeDialog">
                close
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <CreateShiftMachineUsageLog  :clearForm="clearForm" v-on:close="dialog = false" />
        </v-card>
      </v-dialog>
    </div>
  </template>
  <script>
  import CreateShiftMachineUsageLog from '@/components/crud_components/loggers/CreateShiftMachineUsageLog'
  export default {
    name: 'CreateShiftMachineUsageLogModal',
    components: {
        CreateShiftMachineUsageLog
    },
    data() {
      return {
        dialog: false,
        clearForm:false
      }
    },
    methods: {
      closeDialog(){
        this.dialog = false
        this.clearForm = true
      }
    }
  }
  </script>