<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-card :dark="$store.getters.getColorPalette().isDark"  :style="{'border': '1px solid '+ $store.getters.getColorPalette().accentCode, 'background-color':$store.getters.getColorPalette().backgroundColorCode}" flat outlined class="pa-3">
            <v-form ref="logForm" v-model="valid">
                <v-row>
                    <v-col>
                        <v-autocomplete
                            :items="scopeOptions"
                            :dark="$store.getters.getColorPalette().isDark" 
                            :color="$store.getters.getColorPalette().inputtextColor"
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                            outlined
                            dense
                            small-chips
                            :label="$store.getters.getTextMap().scope"
                            item-text="label"
                            item-value="value"
                            v-model="scope"
                        ></v-autocomplete>
                    </v-col>
                    <v-col>
                        <v-autocomplete
                            :items="sourceOptions"
                            :dark="$store.getters.getColorPalette().isDark" 
                            :color="$store.getters.getColorPalette().inputtextColor"
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                            outlined
                            dense
                            small-chips
                            :label="$store.getters.getTextMap().source"
                            item-text="label"
                            item-value="value"
                            v-model="source"
                        ></v-autocomplete>
                    </v-col>
                    <v-col>
                        <v-autocomplete
                            :items="typeOptions"
                            :dark="$store.getters.getColorPalette().isDark" 
                            :color="$store.getters.getColorPalette().inputtextColor"
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                            outlined
                            dense
                            small-chips
                            :label="$store.getters.getTextMap().source_type"
                            item-text="label"
                            item-value="value"
                            v-model="type"
                        ></v-autocomplete>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-autocomplete
                            :items="nameOptions"
                            :dark="$store.getters.getColorPalette().isDark" 
                            :color="$store.getters.getColorPalette().inputtextColor"
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                            outlined
                            dense
                            small-chips
                            :label="$store.getters.getTextMap().name"
                            item-text="label"
                            item-value="value"
                            v-model="name"
                        ></v-autocomplete>
                    </v-col>
                    <v-col>
                        <v-autocomplete
                            :items="uomOptions"
                            :dark="$store.getters.getColorPalette().isDark" 
                            :color="$store.getters.getColorPalette().inputtextColor"
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                            outlined
                            dense
                            small-chips
                            :label="$store.getters.getTextMap().unit_of_measurement"
                            item-text="label"
                            item-value="value"
                            v-model="uom"
                        ></v-autocomplete>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-menu :dark="$store.getters.getColorPalette().isDark"
                            v-model="menu1"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                            :color="$store.getters.getColorPalette().inputtextColor"
                        >
                             <template v-slot:activator="{ on, attrs }">
                                <v-text-field :dark="$store.getters.getColorPalette().isDark"
                                    v-model="from_date"
                                    :label="$store.getters.getTextMap().from_date_optional"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    outlined
                                    dense
                                    :loading="loading"
                                    v-bind="attrs"
                                    v-on="on"
                                    :color="$store.getters.getColorPalette().inputtextColor"
                                ></v-text-field>
                            </template>
                            <v-date-picker :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().inputtextColor" v-model="from_date" @input="menu = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col>
                        <v-menu :dark="$store.getters.getColorPalette().isDark"
                            v-model="menu2"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                            :color="$store.getters.getColorPalette().inputtextColor"
                        >
                             <template v-slot:activator="{ on, attrs }">
                                <v-text-field :dark="$store.getters.getColorPalette().isDark"
                                    v-model="to_date"
                                    :label="$store.getters.getTextMap().to_date"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    outlined
                                    dense
                                    :loading="loading"
                                    v-bind="attrs"
                                    v-on="on"
                                    :rules="ISODateRules"
                                    :color="$store.getters.getColorPalette().inputtextColor"
                                ></v-text-field>
                            </template>
                            <v-date-picker :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().inputtextColor" v-model="to_date" @input="menu = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col>
                        <v-text-field
                            :dark="$store.getters.getColorPalette().isDark"
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                            :loading="loading"
                            v-model="data"
                            outlined
                            dense
                            :rules="numberRules"
                            :label="$store.getters.getTextMap().data"
                            required
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row v-if="emission_factor" justify="center" align="center">
                    <v-col>
                    <v-card elevation="2" class="pa-3 text-center">
                        <v-card-title class="headline font-weight-bold">{{ $store.getters.getTextMap().tco2 }}</v-card-title>
                        <v-divider></v-divider>
                        <v-row justify="center" align="center">
                            <v-col>
                                <v-simple-table >
                                    <template v-slot:default>
                                        <thead>
                                            <tr >
                                                <th class="text-center ">{{ $store.getters.getTextMap().emission_factor }}</th>
                                                <th class="text-center ">{{ $store.getters.getTextMap().data }}</th>
                                                <th class="text-center ">{{ $store.getters.getTextMap().kgco2 }}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr >
                                                <td>{{ emission_factor }}</td>
                                                <td>{{ data }}</td>
                                                <td>{{ tco2 }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-card>
                    </v-col>
                </v-row>
                <v-row >
                    <v-col align="right">
                        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                        <v-btn class="mr-1" small v-if="!loading" :color="$store.getters.getColorPalette().submitbtnColor" @click="onSubmit">{{ $store.getters.getTextMap().submit }}</v-btn>
                        <v-btn small v-if="!loading" :color="$store.getters.getColorPalette().cancelbtnColor" :dark="$store.getters.getColorPalette().isDark"  @click="close">{{ $store.getters.getTextMap().cancel }}</v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-card>
    </v-container>
</template>
<script >
import InfoAlert from '@/components/InfoAlert'
import FormRules from '@/utillities/FormRules'
import moment from 'moment'
import axios from 'axios'
export default{
    name:'AddESGDataLog',
    components:{
        InfoAlert
    },
    mounted(){
        this.getESGConversions()
    },
    data(){
        return {
            showDismissibleAlert:false,
            info:'',
            loading:false,
            valid:false,
            endpoint:'AddESGData',
            menu1:false,
            menu2:false,
            esgConversions:[],
            scopeOptions:[],
            sourceOptions:[],
            typeOptions:[],
            nameOptions:[],
            uomOptions:[],
            scope:null,
            source:null,
            type:null,
            name:null,
            uom:null,
            emission_factor:null,
            data:0,
            from_date:null,
            to_date:null,
            tco2:0,
            form:{},
            rules:FormRules.rules,
            numberRules: FormRules.numberRules,
            ISODateRules:[
                v => !!v || 'Required',
                v => /[0-9][0-9][0-9][0-9]-[0-9][0-9]-[0-9][0-9]$/.test(v) || 'It should be a Date',
            ]
        }
    },
    methods:{
        getEmissionFactor(){
            for(let i of this.esgConversions){
                if(i.scope==this.scope && i.source==this.source && i.type==this.type && i.name==this.name && i.uom==this.uom){
                    this.emission_factor=i.emission_factor
                } 
            }  
        },
        getUOMs(){
            let op=new Set([])
            for(let i of this.esgConversions){
                if(i.scope==this.scope && i.source==this.source && i.type==this.type && i.name==this.name){
                    op.add({label:i.uom,value:i.uom})
                }
            }
            this.uomOptions=[...op]
        },
        getNames(){
            let op=new Set([])
            for(let i of this.esgConversions){
                if(i.scope==this.scope && i.source==this.source && i.type==this.type){
                    op.add({label:i.name,value:i.name})
                }
            }
            this.nameOptions=[...op]
        },
        getTypes(){
            let op=new Set([])
            for(let i of this.esgConversions){
                if(i.scope==this.scope && i.source==this.source){
                    op.add({label:i.type,value:i.type})
                }
            }
            this.typeOptions=[...op]
        },
        getSource(){
            let op=new Set([])
            for(let i of this.esgConversions){
                if(i.scope==this.scope){
                    op.add({label:i.source,value:i.source})
                }
            }
            this.sourceOptions=[...op]
        },
        getScopes(){
            let op=new Set([])
            for(let i of this.esgConversions){
                op.add({label:"Scope "+i.scope,value:i.scope})
            }
            this.scopeOptions=[...op]
        },
        getESGConversions(){
            this.loading=true
            axios.get(this.$store.state.api+'esg_scope_conversion',{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response=>{
                if(response.data.status=='success'){
                    let temp=[]
                    for(let i of response.data.data){
                        if(!i.automated){
                            temp.push(i)
                        }
                    }
                    this.esgConversions=temp
                }else{
                    this.info=response.data.msg
                    this.showDismissibleAlert=true
                }
                this.loading=false
            }).catch(err=>{
                this.loading=false
                this.info=err
                this.showDismissibleAlert=true   
            })
        },
        onSubmit(){
            this.$refs.logForm.validate()
            if(this.valid){
                this.loading=true
                this.form['scope']=this.scope
                this.form['source']=this.source
                this.form['type']=this.type
                this.form['name']=this.name
                this.form['uom']=this.uom
                this.form['emission_factor']=this.emission_factor
                this.form['data']=this.data
                this.form['tco2']=this.tco2
                if(this.from_date){
                    this.form['from_date']=moment(this.from_date).format("YYYY-MM-DD")
                }
                this.form['to_date']=moment(this.to_date).format("YYYY-MM-DD")
                axios.post(this.$store.state.api+this.endpoint,this.form,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response=>{
                    if(response.data.status==='success'){
                        this.dialog=false
                        this.loading=false
                        this.$emit('close')
                    }else{
                        this.info=response.data.msg
                        this.showDismissibleAlert=true
                        this.loading=false
                    }
                })
                .catch(err=>{
                    console.error(err)
                    this.info=err
                    this.showDismissibleAlert=true
                    this.loading=false
                });
            }
            this.loading=false
        },
        close(){
            this.dialog=false
            this.$emit('close')
        }
    },
    watch:{
        esgConversions(){
            this.getScopes()
        },
        scope(){
            this.getSource()
        },
        source(){
            this.getTypes()
        },
        type(){
            this.getNames()
        },
        name(){
            this.getUOMs()
        },
        uom(){
            this.getEmissionFactor()
        },
        data(){
            this.tco2=Math.round(Number(this.emission_factor * this.data)*100)/100
        },
    }
}
</script>