<template>
  <v-form ref="groupForm" v-model="valid">
    <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert = false" :info="info" />
    <v-card>
      <v-card-title class="headline">
        {{ $store.getters.getTextMap().write_in_logbook }}
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12" md="3" lg="6" xl="4">
              <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
              <v-menu v-if="!loading" v-model="menu" :close-on-content-click="false" :nudge-right="40"
                transition="scale-transition" offset-y min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="form.date" outlined dense label="From Date" prepend-icon="mdi-calendar"
                    readonly v-bind="attrs" v-on="on" :rules="objectRules"></v-text-field>
                </template>
                <v-date-picker v-model="form.date" @input="menu = false"></v-date-picker>
              </v-menu>
            </v-col>


            <v-col cols="12" sm="12" md="3" lg="6" xl="4">
              <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
              <v-menu v-if="!loading" ref="menufrom" v-model="menu3" :close-on-content-click="false" :nudge-right="40"
                :return-value.sync="form.fromTime" transition="scale-transition" offset-y max-width="290px"
                min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field outlined dense v-model="form.fromTime" label="From Time"
                    prepend-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" v-on="on"
                    :rules="objectRules"></v-text-field>
                </template>
                <v-time-picker v-if="menu3" v-model="form.fromTime" full-width format="24hr"
                  @click:minute="$refs.menufrom.save(form.fromTime)"></v-time-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" sm="12" md="3" lg="6" xl="4">
              <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
              <v-menu v-if="!loading" v-model="menu1" :close-on-content-click="false" :nudge-right="40"
                transition="scale-transition" offset-y min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field outlined dense v-model="form.to_date" label="To Date" prepend-icon="mdi-calendar"
                    readonly v-bind="attrs" v-on="on" :rules="objectRules"></v-text-field>
                </template>
                <v-date-picker v-model="form.to_date" @input="menu1 = false"></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" sm="12" md="3" lg="6" xl="4">
              <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
              <v-menu v-if="!loading" ref="menuto" v-model="menu2" :close-on-content-click="false" :nudge-right="40"
                :return-value.sync="form.toTime" transition="scale-transition" offset-y max-width="290px"
                min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field outlined dense v-model="form.toTime" label="To Time"
                    prepend-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" v-on="on"
                    :rules="objectRules"></v-text-field>
                </template>
                <v-time-picker v-if="menu2" v-model="form.toTime" full-width format="24hr"
                  @click:minute="$refs.menuto.save(form.toTime)"></v-time-picker>
              </v-menu>
            </v-col>
          </v-row>

          <v-row>

            <v-col cols="12" sm="12" md="3" lg="4" xl="4">

              <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
              <v-select v-if="!loading && dynamicLog.primary_field_type == 'list'" v-model="form.primary_field_data"
                :items="primaryFieldOptions" :label="dynamicLog.primary_field_label" dense filled item-text="label"
                item-value="value" required :rules="objectRules"
                :menu-props="{ dark: $store.getters.getColorPalette().isDark }"></v-select>
              <v-text-field v-else outlined dense :loading="loading" v-model="form.primary_field_data" :counter="8"
                :rules="[rules.required]" :label="dynamicLog.primary_field_label" required></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="3" lg="4" xl="4" v-for="field in metaItems" :key="field.label">

              <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
              <v-select v-if="!loading && field.type == 'list'" v-model="metaData[field.label]"
                :items="getListOptions(field.field)" :label="field.label" dense outlined filled item-text="label"
                item-value="value" required :rules="objectRules"
                :menu-props="{ dark: $store.getters.getColorPalette().isDark }"></v-select>
              <v-text-field v-else :loading="loading" v-model="metaData[field.label]" :counter="8"
                :rules="[rules.required]" :label="field.label" required outlined dense></v-text-field>
            </v-col>
          </v-row>


        </v-container>

        <!-- <table  :dark="$store.getters.getColorPalette().isDark"  :style="{'border': '1px solid '+$store.getters.getColorPalette().accentCode}">
    
        <tr :style="{'background-color':$store.getters.getColorPalette().foregroundColorCode, 'color':$store.getters.getColorPalette().accentCode}">
          <th class="text-left" :style="{'background-color':$store.getters.getColorPalette().foregroundColorCode, 'color':$store.getters.getColorPalette().accentCode}">
            <strong>From Date</strong>
          </th>
          <th class="text-left" :style="{'background-color':$store.getters.getColorPalette().foregroundColorCode, 'color':$store.getters.getColorPalette().accentCode}">
            <strong>From Time</strong>
          </th>
          <th class="text-left" :style="{'background-color':$store.getters.getColorPalette().foregroundColorCode, 'color':$store.getters.getColorPalette().accentCode}">
            <strong>To Date</strong>
          </th>
          <th class="text-left" :style="{'background-color':$store.getters.getColorPalette().foregroundColorCode, 'color':$store.getters.getColorPalette().accentCode}">
            <strong>To Time</strong>
          </th>
          <th class="text-left" :style="{'background-color':$store.getters.getColorPalette().foregroundColorCode, 'color':$store.getters.getColorPalette().accentCode}">
            <strong>{{dynamicLog.primary_field_label}}</strong>
          </th>
          <th class="text-left" v-for="field in metaItems" :key="field.label" :style="{'background-color':$store.getters.getColorPalette().foregroundColorCode, 'color':$store.getters.getColorPalette().accentCode}">
            <strong>{{field.label}}</strong>
          </th>
          
        </tr>
      
        <tr>
        <td>
              <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                <v-menu
        v-if="!loading"
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="form.date"
            label="Date"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
            :rules="objectRules"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="form.date"
          @input="menu = false"
        ></v-date-picker>
      </v-menu>
            </td>


<td>
    <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
    <v-menu
        v-if="!loading"
        ref="menufrom"
        v-model="menu3"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="form.fromTime"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="form.fromTime"
            label="From Time"
            prepend-icon="mdi-clock-time-four-outline"
            readonly
            v-bind="attrs"
            v-on="on"
            :rules="objectRules"
          ></v-text-field>
        </template>
        <v-time-picker
          v-if="menu3"
          v-model="form.fromTime"
          full-width
          format="24hr"
          @click:minute="$refs.menufrom.save(form.fromTime)"
        ></v-time-picker>
      </v-menu>
    </td>

            <td>
              <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                <v-menu
        v-if="!loading"
        v-model="menu1"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="form.to_date"
            label="To Date"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
            :rules="objectRules"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="form.to_date"
          @input="menu1 = false"
        ></v-date-picker>
      </v-menu>
            </td>

            <td>
                <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
<v-menu
v-if="!loading"
        ref="menuto"
        v-model="menu2"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="form.toTime"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="form.toTime"
            label="To Time"
            prepend-icon="mdi-clock-time-four-outline"
            readonly
            v-bind="attrs"
            v-on="on"
            :rules="objectRules"
          ></v-text-field>
        </template>
        <v-time-picker
          v-if="menu2"
          v-model="form.toTime"
          full-width
          format="24hr"
          @click:minute="$refs.menuto.save(form.toTime)"
        ></v-time-picker>
      </v-menu>
            </td>
            <td>
                <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
    
    <v-select
        v-if="!loading && dynamicLog.primary_field_type=='list'"
        v-model="form.primary_field_data"
        :items="primaryFieldOptions"
        :label="dynamicLog.primary_field_label"
        dense
        filled
        item-text="label"
        item-value="value"
        required
        :rules="objectRules"
        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
    ></v-select>
            <v-text-field
            v-else
    :loading="loading"
      v-model="form.primary_field_data"
      :counter="8"
      :rules="[rules.required]"
      :label="dynamicLog.primary_field_label"
      required
    ></v-text-field>
            </td>
    
        <td v-for="field in metaItems" :key="field.label">

                        <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
    <v-select
        v-if="!loading && field.type=='list'"
        v-model="metaData[field.label]"
        :items="getListOptions(field.field)"
        :label="field.label"
        dense
        filled
        item-text="label"
        item-value="value"
        required
        :rules="objectRules"
        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
    ></v-select>
            <v-text-field
            v-else
    :loading="loading"
      v-model="metaData[field.label]"
      :counter="8"
      :rules="[rules.required]"
      :label="field.label"
      required
    ></v-text-field>
        </td>
          
        </tr>
      
  </table> -->


      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-if="!loading" :color="$store.getters.getColorPalette().submitbtnColor" text @click="onSubmit">
          {{ $store.getters.getTextMap().write }}
        </v-btn>
        <v-btn v-if="!loading" :color="$store.getters.getColorPalette().submitbtnColor" text @click="onReset">
          {{ $store.getters.getTextMap().cancel}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import InfoAlert from '@/components/InfoAlert'
import FormRules from '@/utillities/FormRules'
export default {
  name: 'writeDynamicLog',
  props: {
    'dynamicLog': {
      type: Object,
      required: true
    }

  },
  components: {
    InfoAlert
  },
  mounted() {
    this.init()

  },
  data() {
    return {
      loading: false,
      info: '',
      showDismissibleAlert: false,
      valid: false,
      menu: null,
      menu1: null,
      menu2: null,
      menu3: null,
      metaData: {},
      meta: {},
      form: {
        param: null,
        output: null,
        input: null,
        remarks: null,
        fromTime: null,
        toTime: null,
        date: null,
      },
      rules: FormRules.rules,
      objectRules: [
        FormRules.rules.required,
      ],
      floatRules: [
        // v => !!v || 'Required',
        v => (!v || !isNaN(Number(v))) || 'It should be a number',
        v => (!v || (v && v > 0)) || 'It should be more than zero'
      ],
      logRules: [
        v => !!v || 'Required',
        v => /\S+/.test(v) || 'Required',
        v => (v && v.length <= 100 && v.length > 0) || 'Log must be less than 100 characters',
      ],
    }
  },
  computed: {

    fromTimeStamp() {
      if (this.form.date) {

        let dt = this.form.date.toString()
        if (this.form.fromTime) {
          dt += ' ' + this.form.fromTime.toString()
          return Number(moment(dt, 'YYYY-MM-DD HH:mm').format("X"));
        }
        return Number(moment(dt, 'YYYY-MM-DD').format("X"));

      } else {
        return null
      }
    },
    toTimeStamp() {
      if (this.form.to_date) {
        let dt = this.form.to_date.toString()
        if (this.form.toTime) {
          dt += ' ' + this.form.toTime.toString()
          return Number(moment(dt, 'YYYY-MM-DD HH:mm').format("X"));
        }
        return Number(moment(dt, 'YYYY-MM-DD').format("X"));

      } else if (this.form.date) {

        let dt = this.form.date.toString()
        if (this.form.toTime) {
          dt += ' ' + this.form.toTime.toString()
          return Number(moment(dt, 'YYYY-MM-DD HH:mm').format("X"));
        }
        return Number(moment(dt, 'YYYY-MM-DD').format("X"));

      } else {
        return null
      }
    },

    primaryFieldOptions() {
      let op = []
      if (this.dynamicLog && this.dynamicLog.primary_field_type == 'list' && this.dynamicLog.primary_field) {
        let lst = this.$store.getters.getListDataById(this.dynamicLog.primary_field)
        for (let i of lst) {
          op.push({
            label: i.tag,
            value: i.value
          })
        }

      }
      return op
    },
    metaItems() {
      if (this.meta) {
        return Object.values(this.meta)
      }
      return []
    }


  },
  methods: {
    init() {
      if (this.dynamicLog) {
        this.meta = Object.assign({}, this.dynamicLog.meta)
        console.log(this.meta)
      }
    },
    getListOptions(list_id) {
      let op = []
      let lst = this.$store.getters.getListDataById(list_id)
      for (let i of lst) {
        op.push({
          label: i.tag,
          value: i.value
        })
      }
      return op
    },
    onSubmit() {
      this.$refs.groupForm.validate()
      if (this.valid) {
        //console.log("entered")
        this.loading = true
        //evt.preventDefault()
        this.form.log_id = this.dynamicLog.log_id
        this.form.from_timestamp = this.fromTimeStamp
        this.form.to_timestamp = this.toTimeStamp
        this.form.meta_data = this.metaData
        let d = Object.assign({}, this.form)
        //let items=Object.assign({}, this.form.id)
        //d['parameter']=this.parameter.parameter
        //console.log(d)

        axios.post(this.$store.state.api + 'writeDynamicLog', d, {
          headers: {
            Authorization: 'Bearer ' + this.$store.state.jwt
          }
        })
          .then(response => {
            if (response.data.status === 'success') {
              //this.$store.commit('addParameter',d)
              //this.$store.dispatch('refreshParameters')
              //console.log("Parameter added "+d)

              this.loading = false
              this.info = "Log Written"
              this.showDismissibleAlert = true
              //this.dialog=false
              this.$refs.groupForm.reset()
              this.$emit('close')

            } else {
              //console.log(response.data.msg)
              this.info = response.data.msg;
              //this.info = d;
              this.showDismissibleAlert = true;
              //console.log(response.data.msg)
              this.loading = false
            }
          })
          .catch(err => {
            console.log(err)
            this.info = err
            this.loading = false
            this.showDismissibleAlert = true
          });
        //this.$store.dispatch('addDevice', d)

      }
    },
    onReset() {
      //evt.preventDefault()
      /*this.form={
          id:null,
          msg:null,
          name:null
      }*/
      this.$refs.groupForm.reset()
      this.$emit('close')
    }
  },
  watch: {
    "dynamicLog.meta": {
      handler() {

        this.init()
      },
      deep: true
    },
  }
}
</script>
<style scoped>
table {
  display: block;
  overflow-x: auto;
  white-space: nowrap;

}
</style>