<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-form ref="logForm" v-model="valid" >
            <v-card flat :color="$store.getters.getColorPalette().background2ColorCode" :dark="$store.getters.getColorPalette().isDark">
                <v-card-title :color="$store.getters.getColorPalette().accentCode"> {{mode}} data logger</v-card-title>
                <v-card-text>
                    <v-container fluid>
                        <v-row>
                            <v-col>
                                <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                                <v-text-field :dark="$store.getters.getColorPalette().isDark" 
                                    :color="$store.getters.getColorPalette().inputtextColor"
                                    v-if="!loading"
                                    v-model="form.name"
                                    :counter="30"
                                    :rules="nameRules"
                                    :label="$store.getters.getTextMap().name"
                                    ref="Name"
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col style="margin-top:2%" v-if="mode==='edit'" >
                                <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                                <v-btn text outlined class="mx-2" small v-if="!loading" :color="$store.getters.getColorPalette().submitbtnColor" @click="onSubmit">
                                   {{ $store.getters.getTextMap().submit }}
                                </v-btn>
                                <v-btn small text outlined v-if="!loading"  :color="$store.getters.getColorPalette().cancelbtnColor" :dark="$store.getters.getColorPalette().isDark" @click="close">
                                    {{ $store.getters.getTextMap().cancel }}
                                </v-btn>
                                <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                            </v-col>
                        </v-row>
                        <v-divider style="margin-bottom: 2%;margin-top:2%"></v-divider>
                        <v-row>
                            <v-col>
                                <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                                <v-text-field :dark="$store.getters.getColorPalette().isDark" 
                                    :color="$store.getters.getColorPalette().inputtextColor"
                                    v-if="!loading"
                                    v-model="label"
                                    :counter="30"
                                    :label="$store.getters.getTextMap().label"
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col>
                                <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                                <v-select
                                   :dark="$store.getters.getColorPalette().isDark" 
                                   :color="$store.getters.getColorPalette().inputtextColor"
                                   :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                    v-if="!loading"
                                    v-model="workspace"
                                    :items="workspaceOptions"
                                    :label="$store.getters.getTextMap().workspace"
                                    ref="workspace"
                                    outlined          
                                    item-text="label"
                                    item-value="value"
                                ></v-select>
                            </v-col>
                            <v-col>
                                <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                                <v-select
                                    :dark="$store.getters.getColorPalette().isDark" 
                                    :color="$store.getters.getColorPalette().inputtextColor"
                                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                    v-if="!loading"
                                    v-model="machine"
                                    :items="machineOptions"
                                    :label="$store.getters.getTextMap().machine"
                                    ref="machine"
                                    outlined
                                    item-text="label"
                                    item-value="value"
                                ></v-select>
                            </v-col> 
                            <v-col>
                                <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                                <v-select
                                    :dark="$store.getters.getColorPalette().isDark" 
                                    :color="$store.getters.getColorPalette().inputtextColor"
                                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                    v-if="!loading"
                                    v-model="param"
                                    :items="paramOptions"
                                    :label="$store.getters.getTextMap().parameter"
                                    outlined
                                    item-text="label"
                                    item-value="value"
                                ></v-select>
                            </v-col> 
                            <v-col v-if="mode==='edit'" style="margin-top: 1%;">
                                <v-btn  small outlined v-if="!loading"  :color="$store.getters.getColorPalette().accentCode" text @click="addParamToList">
                                    {{$store.getters.getTextMap().add_param}}
                                </v-btn>
                            </v-col>
                            <v-col v-else style="margin-top: 1%;">
                                <v-btn  small outlined v-if="!loading"  :color="$store.getters.getColorPalette().accentColor" text @click="addParamToListwhileCreate">
                                    {{$store.getters.getTextMap().add_param}}
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-card :dark="$store.getters.getColorPalette().isDark" :color="'transparent'" flat>
                                    <v-row v-if="!loading && paramDetailsList " style="margin-top:20px;">
                                        <v-col cols="12">
                                            <v-simple-table height="200px" :style="{background: 'transparent'}" >
                                                <template v-slot:default>
                                                    <thead>
                                                        <tr>
                                                            <th class="text-left">
                                                                <strong>{{$store.getters.getTextMap().param_id}}</strong>
                                                            </th>
                                                            <th class="text-left">
                                                                <strong>{{$store.getters.getTextMap().label}}</strong>
                                                            </th>
                                                            <th class="text-left">
                                                                <strong>{{$store.getters.getTextMap().workspace}}</strong>
                                                            </th>
                                                            <th class="text-left">
                                                                <strong>{{$store.getters.getTextMap().machine}}</strong>
                                                            </th>
                                                            <th class="text-left">
                                                                <strong>{{$store.getters.getTextMap().param}}</strong>
                                                            </th>
                                                         
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr
                                                        :key="item.param_id" v-for="item in paramDetailsList"
                                                        >
                                                        <td>{{ item.param_id }}</td>
                                                        <td>{{ item.label}}</td>
                                                        <td>{{ item.workspace }}</td>
                                                        <td>{{ item.machine }}</td>
                                                        <td>{{ item.param }}</td>
                                                        
                                                        <td>
                                                    <DeleteConfirmation v-on:confirm="removeParamFromList(item)"  title="Delete Confirmation"  description="Are you sure you want to delete this?">    
                                                    <v-icon 
                                                    small 
                                                    color="red" >
                                                    mdi-delete
                                                    </v-icon>
                                                    </DeleteConfirmation>
                                                        </td>
                                                        </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                        </v-col>
                                    </v-row>
                                </v-card>   
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
            <v-row v-if="mode!=='edit'" style="margin-top: 0.5%;">
                <v-col align="right">
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-btn class="mx-2" outlined  v-if="!loading" :color="$store.getters.getColorPalette().submitbtnColor" @click="onSubmit">
                        Submit
                    </v-btn>
                    <v-btn outlined  v-if="!loading" :color="$store.getters.getColorPalette().cancelbtnColor" :dark="$store.getters.getColorPalette().isDark" @click="close">
                        Cancel
                    </v-btn>
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import DeleteConfirmation from '@/components/DeleteConfirmation'
import axios from 'axios'
export default {
    name:'CreateDataLog',
    props:['dataLog'],
    components:{
        InfoAlert,
        DeleteConfirmation
    },
    data(){
        return {
            showDismissibleAlert:false,
            info:'',
            valid:false,
            loading:false,
            api:'createDataLogger',
            mode:'create',
            workspace:null,
            machine:null,
            machineName:null,
            workspaceName:null,
            paramName:null,
            param:null,
            label:null,
            machineOptions:[],
            paramOptions:[],
            paramDetailsList:[],    
            form:{
                name:null,
                meta:null,
                machine:null,
                workspace:null,
                param:null
            },
            param_form:{},
            nameRules: [
                v => !!v || 'Name is required',
                v => /\S+/.test(v) || 'Name is required',
                v => (v && v.length <= 30 && v.length > 0) || 'Name must be less than 30 characters',
            ],
            objectRules:[
                v => !!v || 'Required',
            ],
        }
    },
    mounted(){
        if(this.dataLog && this.dataLog.log_id){
            console.log(this.dataLog);// all the data about this dataLog were got here 
                this.form=Object.assign({},this.dataLog)

                //If the mode is edited this paramDetailsList is rendering in the data-table

                for(let i of this.dataLog.paramDetailsList){
                    this.paramDetailsList.push({param_id:i.param_id,label:i.label,machine:i.machine_name,workspace:i.workspace_name,param:i.param_name})
                }
                 
            //    console.log(this.paramDetailsList);
                
                this.mode='edit'
                this.api='updateDataLogger'
            }else{
                this.mode='create'
                this.api='createDataLogger'
            }
        },
    computed:{

        // here we got workspace dropdown
        workspaceOptions(){
        let op=[]
        let g=this.$store.state.workspaces
        for(let i of g){
            op.push( {value:i.workspace_id, label: i.name })
        }
        return op
         },
    },
    methods:{
        //machine dropdown options we got from here
        getMachineList(){
            let op=[]
            if(this.workspace){
                let payload={
                    workspace_id:this.workspace
                }
                this.loading = true;
                axios.post(this.$store.state.api + "getMachinesByWorkspace", payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response => {
                    if (response.data.status == "success") {
                        let machines=response.data.machines
                    for(let i of machines){
                        if(i && i.name){
                            op.push({value:i.machine_id, label: i.name })
                        }else{
                            op.push({value:i.machine_id, label: i.machine_id })
                        }
                    }
                    } else {
                        this.info = response.data.msg;
                        this.showDismissibleAlert = true;
                    }
                    this.loading = false;
                })
                .catch(error => {
                    this.info = error;
                    this.showDismissibleAlert = true;
                    this.loading = false;
                });
            }
            this.machineOptions=op
            this.changeSelectedLabelName(this.machineOptions)
           
        },

        //param dropdown options are got from here
        getLoggedParamsFromMachine(){
            let op=[]
            if(this.machine){
                let payload={
                    machine_id:this.machine
                }
                this.loading = true;
                axios.post(this.$store.state.api + "getLoggedParamsFromMachine", payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response => {
                    if (response.data.status == "success") {
                        let params=response.data.params
                    for(let i of params){
                        if(i && i.name){
                            op.push({value:i.param_id, label: i.name })
                        }else{
                            op.push({value:i.param_id, label: i.param_id })
                        }
                    }
                    } else {
                        this.info = response.data.msg;
                        this.showDismissibleAlert = true;
                    }
                    this.loading = false;
                })
                .catch(error => {
                    this.info = error;
                    this.showDismissibleAlert = true;
                    this.loading = false;
                });
            }
            this.paramOptions=op
            this.changeSelectedLabelName(this.paramOptions)
        },

        // In edit mode the params details are added in the list
        addParamToList(){
           
            this.param_form.log_id=this.dataLog.log_id
            this.param_form.param_id=this.param
            this.param_form.label=this.label
            this.param_form.machine=this.machineName
            this.param_form.workspace=this.workspaceName
            this.param_form.param=this.paramName

            axios.post(this.$store.state.api+'addParamToDataLoggerList',this.param_form,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response=>{
                if(response.data.status=='success'){
                    this.loading=false
                    this.paramDetailsList.push({param_id:this.param,label:this.label,text_data:null,machine:this.machineName,workspace:this.workspaceName,param:this.paramName})
                    this.$store.dispatch('refreshDataLog')
                }else{
                    this.loading=false
                    this.info=response.data.msg
                    this.showDismissibleAlert=true
                }
            }).catch(err=>{
                this.loading=false
                this.info=err
                this.showDismissibleAlert=true
                
            })

            this.$store.dispatch('refreshDataLog')
        },  


        //In create mode this data is added  in the list
        addParamToListwhileCreate(){
            console.log(this.machine);
            this.paramDetailsList.push({param_id:this.param,label:this.label,text_data:null,machine:this.machineName,workspace:this.workspaceName,param:this.paramName})
            console.log(this.paramDetailsList)
        },

        // Both edit and create submit buttons triggers this function
        onSubmit(){
            this.$refs.logForm.validate()
            if(this.valid){
                this.loading=true
                if(this.mode!=='edit'){
                    this.form.param_details=this.paramDetailsList            
                }
                axios.post(this.$store.state.api + this.api,this.form,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response=>{
                    if(response.data.status=='success'){
                        this.loading=false
                        this.info=response.data.msg
                        this.$store.dispatch('refreshDataLog')
                        this.close()
                    }else{
                        this.loading=false
                        this.info=response.data.msg
                        this.showDismissibleAlert=true
                    }
                    }).catch(err=>{
                        this.loading=false
                        this.info=err
                        this.showDismissibleAlert=true
                    })
            }
        },

        //changing machine,workspace,param ids as label using the data we passed to the dropdowns from the database.
        changeSelectedLabelName(data){
            if(data &&  data.length>0){
                for( let i of data){
                    if(i.value === this.workspace)
                    {
                    this.workspaceName = i.label
                    }else if(i.value === this.machine){
                       this.machineName = i.label
                    }else if(i.value === this.param){
                       this.paramName = i.label
                    //    console.log(this.paramName);
                    }
                }
            }
          }  
        ,

        // closing dialog
        close(){
            this.loading=false
            this.$emit('close')
        },
        //delete param from the list
        removeParamFromList(m){
            this.paramDetailsList=this.paramDetailsList.filter(x=>x.param_id!=m.param_id)
            this.param_form.param_id=m.param_id
            this.param_form.log_id = this.dataLog.log_id
            axios.post(this.$store.state.api+'removeParamFromDataLoggerList',this.param_form,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response=>{
                if(response.data.status=='success'){
                    this.loading=false
                    this.$store.dispatch('refreshDataLog')
                }else{
                    this.loading=false
                    this.info=response.data.msg
                    this.showDismissibleAlert=true
                }
            }).catch(err=>{
                this.loading=false
                this.info=err
                this.showDismissibleAlert=true
                
            })
        },  
    },

    watch:{
        //watcher for workspace flag in data
        workspace(){
            this.getMachineList()
            this.changeSelectedLabelName(this.workspaceOptions)
        },
        //watcher for machine flag in data
        machine(){

            this.getLoggedParamsFromMachine()
            this.changeSelectedLabelName(this.machineOptions)
        },
        //watcher for param flag in data
        param(){
            this.changeSelectedLabelName(this.paramOptions)
        },
        
    }
}
</script>
<style scoped>

</style>