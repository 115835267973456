<template>
    <v-lazy v-model="isActive" :options="{threshold: .5}" transition="fade-transition">
        <div :color="$store.getters.getColorPalette().accentCode">
            <v-card :dark="$store.getters.getColorPalette().isDark" @click="dialog = true" class="px-2 py-0 mx-1 my-2 "  :color="$store.getters.getColorPalette().loggerColor" flat >
                <v-card-title >{{$store.getters.getTextMap().esg_data_logs  }}<v-spacer></v-spacer><v-icon color="orange lighten-1"> mdi-pine-tree-variant-outline</v-icon></v-card-title>
            </v-card>
            <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
                <v-card :dark="$store.getters.getColorPalette().isDark"  :color="$store.getters.getColorPalette().backgroundColorCode">
                    <v-toolbar :color="$store.getters.getColorPalette().foregroundColorCode" :dark="$store.getters.getColorPalette().isDark">
                        <v-btn :dark="$store.getters.getColorPalette().isDark"  @click="dialog = false" icon><v-icon>mdi-close</v-icon></v-btn>
                        <v-toolbar-title>{{$store.getters.getTextMap().esg_data_logs  }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn text @click="dialog = false" :dark="$store.getters.getColorPalette().isDark" >{{$store.getters.getTextMap().close  }}</v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                    <ESGDataLogDetails />
                </v-card>
            </v-dialog>
        </div>
    </v-lazy>
</template>
<script>
import ESGDataLogDetails from '@/components/panels/loggers/ESGDataLogDetails'
export default {
    name:'ESGDataLogger',
    components:{
        ESGDataLogDetails,
    },
    data(){
        return {
            isActive:false,
            dialog:false,
        }
    },
}
</script>
    
    
    