<template>
    <div>
        <!-- <h1 class="text-subtitle-2 text-capitalize grey--text upper ma-4">Log Book</h1> -->
        
        <v-container fluid>
            <v-row >
                <v-col cols="auto" class="d-flex">
                    <AccessIndustryLog/>
                    
                </v-col>
                <v-col cols="auto" >
                    <AddESGDataLogBottomSheet v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess']) " />
                </v-col>
                <!-- <v-col cols="auto" >
                    <AddESGDataLogTempBottomSheet v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['logs']) " />
                </v-col> -->
                <!-- <v-col cols="auto" >
                    <CreateESGQuestionBottomSheet v-if="$store.state.user && $store.getters.getAccessByLevels(['betaaccess']) " />
                </v-col> -->
                <v-col cols="auto">
                    <CreateDynamicLogModal v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess']) "/>
                </v-col>
                <v-col cols="auto" >
                    <CreateCapacityLogDialog v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess']) "/>
                </v-col>
                <v-col cols="auto">
                    <CreateProductionLogBottomSheet v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess']) "/>
                </v-col>
                <v-col cols="auto" >
                    <CreateDataLogBottomSheet v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess']) "/>
                </v-col>
                <v-col cols="auto" >
                    <CreateBatchProductionModal v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess']) "/>
                </v-col>
                <v-col cols="auto" >
                    <CreateShiftMachineUsageLogModal v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess']) "/>
                </v-col>
                <!-- <v-col align="left" align-self="center" >
                </v-col >
                <v-col align="left"  align-self="center"  v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess']) ">
                </v-col>
                <v-col align="left"  align-self="center"  v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess']) ">
                </v-col>
                <v-col align="left"  align-self="center"  v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess']) ">
                </v-col>
                <v-col align="left"  align-self="center" v-if="$store.state.user  && $store.getters.getAccessByLevels(['betaaccess']) ">
                </v-col> -->
            </v-row>
            <v-row no-gutters>
                <v-col>
                    <ProductionLoggerList/>
                </v-col>
            </v-row>            
            <v-row no-gutters>
                <v-col>
                    <CapacityLoggerList/>
                </v-col>
            </v-row>                      
            <v-row no-gutters>
                <v-col>
                    <DynamicLoggerList/>
                </v-col>
            </v-row>                      
            <v-row no-gutters>
                <v-col>
                    <BatchProductionLoggerList />
                </v-col>
            </v-row>                      
            <v-row no-gutters>
                <v-col>
                    <ShiftMachineUsageLoggerList />
                </v-col>
            </v-row>                      
            <v-row no-gutters >
                <v-col>
                    <DataLoggerList/>
                </v-col>
            </v-row>                      
            <v-row v-if="$store.state.user && $store.getters.getAccessByLevels(['engineeraccess']) " no-gutters >
                <v-col>
                    <ESGDataLoggerList/>
                </v-col>
            </v-row>                      
            <!-- <v-row v-if="$store.state.user && $store.getters.getAccessByLevels(['logs']) " no-gutters >
                <v-col>
                    <ESGDataLoggerListTemp/>
                </v-col>
            </v-row>                       -->
        </v-container>
    </div>
</template>
<script>
import AccessIndustryLog from '@/components/modals/AccessIndustryLog'
import ProductionLoggerList from '@/components/lists/ProductionLoggerList'
import DataLoggerList from '@/components/lists/DataLoggerList'
import CapacityLoggerList from '@/components/lists/loggers/CapacityLoggerList'
import ESGDataLoggerList from '@/components/lists/loggers/ESGDataLoggerList'
// import ESGDataLoggerListTemp from '@/components/lists/loggers/ESGDataLoggerListTemp'
import DynamicLoggerList from '@/components/lists/loggers/DynamicLoggerList'
import BatchProductionLoggerList from '@/components/lists/loggers/BatchProductionLoggerList'
import CreateProductionLogBottomSheet from '@/components/modals/CreateProductionLogBottomSheet'
import CreateCapacityLogDialog from '@/components/modals/CreateCapacityLogDialog'
import CreateDataLogBottomSheet from '@/components/modals/CreateDataLogBottomSheet'
import CreateDynamicLogModal from '@/components/modals/loggers/CreateDynamicLogModal'
import CreateBatchProductionModal from '@/components/modals/loggers/CreateBatchProductionModal'
import AddESGDataLogBottomSheet from '@/components/modals/loggers/AddESGDataLogBottomSheet'
import CreateShiftMachineUsageLogModal from '@/components/modals/loggers/CreateShiftMachineUsageLogModal'
import ShiftMachineUsageLoggerList from '@/components/lists/loggers/ShiftMachineUsageLoggerList'
// import AddESGDataLogTempBottomSheet from '@/components/modals/loggers/AddESGDataLogTempBottomSheet'
// import CreateESGQuestionBottomSheet from '@/components/modals/modules/esg/CreateESGQuestionBottomSheet'

export default {
    name:'LogBook',
    components:{
        AccessIndustryLog,
        CreateProductionLogBottomSheet,
        CreateCapacityLogDialog,
        ProductionLoggerList,
        DataLoggerList,
        CapacityLoggerList,
        DynamicLoggerList,
        BatchProductionLoggerList,
        CreateDataLogBottomSheet,
        // CreateESGQuestionBottomSheet,
        CreateDynamicLogModal,
        AddESGDataLogBottomSheet,
        ESGDataLoggerList,
        // ESGDataLoggerListTemp,
        CreateBatchProductionModal,
        CreateShiftMachineUsageLogModal,
        ShiftMachineUsageLoggerList
        // AddESGDataLogTempBottomSheet
    },
    mounted() {
        document.title='Logbook'
        if (!this.$store.state.loggedIn) {
      this.$router.push("/");
    }
    },
    data(){
        return {
            search:'',
            loading:false,
            expanded: [],
            logs:[],
            headers: [
          {
            text: 'timestamp',
            align: 'start',
            filterable: false,
            value: 'action_id',
          },
          { text: 'Log', value: 'msg' },
          { text: 'Type', value: 'type' },
          
          
        ],
        }
    },
}
</script>