<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-card flat :color="$store.getters.getColorPalette().background2ColorCode">
            <v-card-title class="headline">{{$store.getters.getTextMap().write_in_data_logbook}}</v-card-title>
            <v-card-text>
                <v-container fluid>
                    <v-row>
                        <v-col>
                            <v-checkbox
                                :dark="$store.getters.getColorPalette().isDark"
                                :color="$store.getters.getColorPalette().inputtextColor"
                                v-model="dailyData"
                                :label="$store.getters.getTextMap().daily_data"
                            ></v-checkbox>
                        </v-col>
                        <v-col>
                            <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                            <v-menu
                                v-if="!loading"
                                v-model="menu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="form.date"
                                        :label="$store.getters.getTextMap().date"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        :rules="objectRules"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="form.date" @input="menu = false"></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col v-if="!dailyData">
                            <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                            <v-menu
                                v-if="!loading"
                                ref="menufrom"
                                v-model="menu3"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                :return-value.sync="form.timestamp"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="form.timestamp"
                                        :label="$store.getters.getTextMap().time"
                                        prepend-icon="mdi-clock-time-four-outline"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-time-picker v-if="menu3" v-model="form.timestamp" full-width format="24hr" @click:minute="$refs.menufrom.save(form.timestamp)"></v-time-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                    <v-row v-if="dataLog">
                         <v-col>
                            <v-card flat :color="$store.getters.getColorPalette().backgroundColorCode">
                                <v-card-text>
                                    <v-form ref="writeLogForm" v-model="valid">
                                        <v-row>
                                            <v-col cols="4" v-for="param in dataLog.paramDetailsList" :key="param.param_id">
                                                <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                                                <v-text-field :dark="$store.getters.getColorPalette().isDark" 
                                                    :color="$store.getters.getColorPalette().inputtextColor"
                                                    v-if="!loading"
                                                    v-model="param_data_details[param.param_id]"
                                                    :rules="floatRules"
                                                    :label="param.label"    
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </v-card-text>
                            </v-card>
                        </v-col> 
                    </v-row>
                    <v-row>
                        <v-col align="right">
                            <v-btn  outlined v-if="!loading"  :color="$store.getters.getColorPalette().submitbtnColor"  @click="WriteDataToParamUsageData">
                              {{  $store.getters.getTextMap().write}}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import axios from 'axios'
import moment from 'moment'

export default {
    name:'WriteDataLog',
    props:['dataLog'],
    components:{
        InfoAlert
    },
    mounted() {
    },
    data(){
        return {
            loading:false,
            info:'',
            showDismissibleAlert:false,
            valid:false,
            menu:null,
            menu2:null,
            menu3:null,
            apiEndpoint:'writeLoggedParamDailyData',
            dailyData:true,
            param_data_details:{},
            form:{
                timestamp:null,
                date:null,
            },
            objectRules:[
                v => !!v || 'Required',
            ],
            floatRules:[
                v => !!v || 'Required',
                v => (v && !isNaN(Number(v))) || 'It should be a number',
                // v => (v && v<0 )|| 'It should be more than zero',
                // v => (v && v.length <= 8 && v.length > 0) || 'It must be less than 8 digits',
            ],
        }
    },
    computed:{
        timeStamp(){
            if(this.form.date){
                let dt=this.form.date.toString()
                if(this.form.timestamp){
                    dt+=' '+this.form.timestamp.toString()
                    return Number(moment(dt,'YYYY-MM-DD HH:mm').format("X"));
                }
                return Number(moment(dt,'YYYY-MM-DD').format("X"));
            
            }else{
                return null
            }
        },
    },
    methods:{
        WriteDataToParamUsageData(){
            this.$refs.writeLogForm.validate()
            if(this.valid){
                let details = []
                for(let i of Object.keys(this.param_data_details)){
                    details.push({'param_id':i,'data':this.param_data_details[i]})
                }
                let payload={
                    details:details,
                }
                this.dailyData?payload['date']=this.form.date.toString():payload['timestamp']=this.timeStamp
                console.log(payload)
                axios.post(this.$store.state.api+this.apiEndpoint,payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response=>{
                    if(response.data.status=='success'){
                        this.loading=false
                        // this.$store.dispatch('refreshDataLog')
                        // this.param_data_details={}
                        // this.form.timestamp=null
                        this.info='Data Logged'
                        this.showDismissibleAlert=true
                        // this.close()
                    }else{
                        this.loading=false
                        this.info=response.data.msg
                        this.showDismissibleAlert=true
                    }
                    }).catch(err=>{
                        this.loading=false
                        this.info=err
                        this.showDismissibleAlert=true
                    })
            }
      },
      close(){
            this.loading=false
            this.$emit('close')
        },
    },
    watch:{
        dailyData:{
            handler(){
                if(this.dailyData){
                    this.apiEndpoint='writeLoggedParamDailyData'
                }else{
                    this.apiEndpoint='writeLoggedParamData'
                }
            },
        deep:true
        }
    }
}
</script>