<template>
    <div class="text-center" >
      <!-- class="devc" -->
      <v-btn
      :background-color="$store.getters.getColorPalette().background1ColorCode"
    :color="$store.getters.getColorPalette().accentCode"

    :dark="$store.getters.getColorPalette().isDark"
       small
       outlined 
       text
        
        
        
        @click="dialog = !dialog"
      >
       {{ $store.getters.getTextMap().create_capacity_logger }}<v-icon
          right
        :dark="$store.getters.getColorPalette().isDark"
       :color="$store.getters.getColorPalette().btnborderColorCode"
        >mdi-clipboard-text-outline
        </v-icon>
      </v-btn>
      <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
      <v-card :style="{background: $store.getters.getColorPalette().backgroundColorCode}">
      <v-toolbar
           :dark="$store.getters.getColorPalette().isDark"
         :color="$store.getters.getColorPalette().foregroundColorCode"
          >
            <v-btn
              icon
          :dark="$store.getters.getColorPalette().isDark"
              @click="dialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title> {{ $store.getters.getTextMap().capacity_log}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn
             :dark="$store.getters.getColorPalette().isDark"
                text
                @click="dialog = false"
              >
               {{ $store.getters.getTextMap().close }}
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
        <CreateCapacityLog v-on:close="dialog=false"/>
        
      </v-card>
      </v-dialog>
    </div>
  </template>
  <script>
  import CreateCapacityLog from '@/components/crud_components/loggers/CreateCapacityLog'
  export default {
      name:'CreateCapacityLogDialog',
      components:{
        CreateCapacityLog  
      },
      data(){
          return {
              dialog:false
          }
      },
      methods:{
          
      }
  }
  </script>
  <style scoped>
  .devc{
     border:3px solid;
    border-radius:20px;
 
    ;
  }
  
  </style>