<template>
    <v-container class="" fluid>
        <span v-if="$store.state.capacityLogs && $store.state.capacityLogs.length>0">
            <v-subheader :style="{ color: $store.getters.getColorPalette().accentCode }">{{$store.getters.getTextMap().capacity_logs}}</v-subheader><v-divider :color="$store.getters.getColorPalette().accentCode"></v-divider> 
        <v-row>
        <v-col  cols="4" v-bind:key="capacityLog.log_id" v-for="capacityLog in $store.state.capacityLogs">
            <CapacityLogger  :capacityLog="capacityLog"/>
        </v-col>
        </v-row>
        </span>
    </v-container>
</template>

<script>
import CapacityLogger from '@/components/items/loggers/CapacityLogger'
export default {
    name:'CapacityLoggerList',
    data(){
        return {

        }
    },
    components:{
        CapacityLogger
    },
    computed:{
   
    },
    methods:{
    }
}
</script>

<style scoped>

</style>