<template>
    <v-form ref="groupForm" v-model="valid">
      <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
      <v-card>
        <v-card-title class="headline">
          {{$store.getters.getTextMap().edit_written_log_book}}
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
                        <v-col>
                            <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                            <v-text-field 
                                outlined 
                                dense 
                                v-if="!loading" 
                                v-model="form.total_production" 
                                :label="$store.getters.getTextMap().total_production"
                                :rules="floatRules">
                            </v-text-field>
                        </v-col>
                        <v-col>
                            <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                            <v-text-field 
                                outlined 
                                dense 
                                v-if="!loading" 
                                v-model="form.rejected" 
                                :label="$store.getters.getTextMap().rejected"
                                :rules="floatRules">
                            </v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field 
                                outlined 
                                dense 
                                v-if="!loading"
                                :counter="100"  
                                v-model="form.machine_operator" 
                                :label="$store.getters.getTextMap().machine_operator"
                                :rules="nameRules">
                            </v-text-field>
                        </v-col>
                    </v-row>
            <v-row>
              <v-col v-for="field in metaItems" :key="field.label">
                <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                <v-select 
                    v-if="!loading && field.type=='list'" 
                    v-model="metaData[field.label]"
                    :items="getListOptions(field.field)" 
                    :label="field.label" 
                    dense 
                    outlined 
                    item-text="label"
                    item-value="value" 
                    required :rules="objectRules"
                    :menu-props="{dark: $store.getters.getColorPalette().isDark}">
                </v-select>
                <v-text-field 
                    dense
                    outlined
                    v-else :loading="loading" 
                    v-model="metaData[field.label]" 
                    :counter="50"
                    :rules="[rules.required,rules.size(50)]" 
                    :label="field.label" 
                    required>
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-if="!loading" :color="$store.getters.getColorPalette().submitbtnColor" text @click="onSubmit">
            {{ $store.getters.getTextMap().re_write }}
          </v-btn>
          <v-btn v-if="!loading" :color="$store.getters.getColorPalette().submitbtnColor" text @click="onReset">
            {{ $store.getters.getTextMap().cancel }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </template>
  
  <script>
    import axios from 'axios'
    import InfoAlert from '@/components/InfoAlert'
    import FormRules from '@/utillities/FormRules'
    export default {
      name: 'EditWrittenShiftMachineUsageLog',
      props: ['shiftMachineUsageLog', 'selectedItem'],
      components: {
        InfoAlert
      },
      mounted() {
        this.init()
        if (this.selectedItem) {
          this.form.total_production =this.selectedItem.total_production;
          this.form.rejected = this.selectedItem.rejected;
          this.form.machine_operator =this.selectedItem.machine_operator;
          this.form.shift_id =this.shiftMachineUsageLog.shift_id;
          this.form.machine_id = this.selectedItem.machine_id;
          this.form.from_time =this.selectedItem.from_time;
          this.form.date = this.selectedItem.date;
          let metaKeys = Object.keys(this.shiftMachineUsageLog .meta)
          for (let key of metaKeys) {
            if (this.selectedItem[key]) {
              this.metaData[key] = this.selectedItem[key]
            }
          }
        }
        
      },
      data() {
        return {
          loading: false,
          info: '',
          show:false,
          showDismissibleAlert: false,
          valid: false,
          metaData: {},
          meta: {},
          form: {
            from_time: null,
            date: null,
            shift_id: null,
            machine_id: null,
            total_production: null,
            rejected: null,
            machine_operator: null,
          },
          rules: FormRules.rules,
          objectRules: [
            FormRules.rules.required,
          ],
          floatRules: [
            // v => !!v || 'Required',
            v => (!v || !isNaN(Number(v))) || 'It should be a number',
            v => (!v || (v && v > 0)) || 'It should be more than zero'
          ],
          nameRules: [
                v => !!v || 'Name is required',
                v => /\S+/.test(v) || 'Name is required',
                v => (v && v.length <= 100 && v.length > 0) || 'Name must be less than 100 characters',
                v => isNaN(parseFloat(v)) || 'Input cannot be a number' // New rule for checking if the input is not a number
            ],
          logRules: [
            v => !!v || 'Required',
            v => /\S+/.test(v) || 'Required',
            v => (v && v.length <= 100 && v.length > 0) || 'Log must be less than 100 characters',
          ],
        }
      },
      computed: {        
        metaItems() {
          if (this.meta) {
            return Object.values(this.meta)
          }
          return []
        }  
      },
      methods: {
        init() {
          if (this.shiftMachineUsageLog) {
            this.meta = Object.assign({}, this.shiftMachineUsageLog.meta)
            // console.log(this.meta)
          }
        },
        getListOptions(list_id) {
          let op = []
          let lst = this.$store.getters.getListDataById(list_id)
          for (let i of lst) {
            op.push({
              label: i.tag,
              value: i.value
            })
          }
          return op
        },
        onSubmit() {
          this.$refs.groupForm.validate()
          if (this.valid) {
           
  
            this.loading = true
            this.form['meta_data'] = this.metaData
            axios.post(this.$store.state.api + 'rewriteShiftMachineUsageLog', this.form, {
              headers: {
                Authorization: 'Bearer ' + this.$store.state.jwt
              }
            })
              .then(response => {
                if (response.data.status === 'success') {                
                  this.loading = false
                  this.info = "Log Re-Written"
                  this.showDismissibleAlert = true
                  this.$store.dispatch('refreshShiftMachineUsageLogs')
                  this.$emit('update')
                  this.$emit('close')
  
                } else {
                  this.info = response.data.msg;
                  this.showDismissibleAlert = true;
                  this.loading = false
                }
              })
              .catch(err => {
                console.log(err)
                this.info = err
                this.loading = false
                this.showDismissibleAlert = true
              });
          }
        },
        onReset() {
          this.$emit('close')
          
        }
      },
      watch: {
        "shiftMachineUsageLog.meta_data": {
          handler() {
            this.init()
          },
          deep: true
        },
      }
    }
  </script>
  <style scoped>
    table {
      display: block;
      overflow-x: auto;
      white-space: nowrap;
  
    }
  </style>