<template>
  <v-form ref="groupForm" v-model="valid">
    <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
    <v-card>
      <v-card-title class="headline">
        {{$store.getters.getTextMap().edit_written_log_book}}
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
              <v-menu v-if="!loading" v-model="menu" :close-on-content-click="false" :nudge-right="40"
                transition="scale-transition" offset-y min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="form.date" :label="$store.getters.getTextMap().from_date" prepend-icon="mdi-calendar" readonly
                    v-bind="attrs" v-on="on" :rules="objectRules" @input="show"></v-text-field>
                    <v-alert v-if="show">{{ $store.getters.getTextMap().field_cant_change }}</v-alert>
                </template>
                <v-date-picker v-model="form.date" @input="menu = false" disabled></v-date-picker>
              </v-menu>
            </v-col>


            <v-col>
              <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
              <v-menu v-if="!loading" ref="menufrom" v-model="menu3" :close-on-content-click="false" :nudge-right="40"
                :return-value.sync="form.fromTime" transition="scale-transition" offset-y max-width="290px"
                min-width="290px" >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="form.fromTime" :label="$store.getters.getTextMap().from_time" prepend-icon="mdi-clock-time-four-outline"
                    readonly v-bind="attrs" v-on="on" :rules="objectRules" ></v-text-field>
                </template>
                <v-time-picker v-if="menu3" v-model="form.fromTime" full-width format="24hr"
                  @click:minute="$refs.menufrom.save(form.fromTime)" disabled></v-time-picker>
              </v-menu>
            </v-col>

            <v-col>
              <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
              <v-menu v-if="!loading" v-model="menu1" :close-on-content-click="false" :nudge-right="40"
                transition="scale-transition" offset-y min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="form.to_date" :label="$store.getters.getTextMap().to_date" prepend-icon="mdi-calendar" readonly
                    v-bind="attrs" v-on="on" :rules="objectRules" ></v-text-field>
                </template>
                <v-date-picker v-model="form.to_date" @input="menu1 = false" disabled></v-date-picker>
              </v-menu>
            </v-col>

            <v-col>
              <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
              <v-menu v-if="!loading" ref="menuto" v-model="menu2" :close-on-content-click="false" :nudge-right="40"
                :return-value.sync="form.toTime" transition="scale-transition" offset-y max-width="290px"
                min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="form.toTime" :label="$store.getters.getTextMap().to_time" prepend-icon="mdi-clock-time-four-outline"
                    readonly v-bind="attrs" v-on="on" :rules="objectRules"></v-text-field>
                </template>
                <v-time-picker v-if="menu2" v-model="form.toTime" full-width format="24hr"
                  @click:minute="$refs.menuto.save(form.toTime)" disabled></v-time-picker>
              </v-menu>
            </v-col>
        
          </v-row>

          <v-row>
            <v-col v-for="field in metaItems" :key="field.label">

              <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
              <v-select v-if="!loading && field.type=='list'" v-model="metaData[field.label]"
                :items="getListOptions(field.field)" :label="field.label" dense filled item-text="label"
                item-value="value" required :rules="objectRules"
                :menu-props="{dark: $store.getters.getColorPalette().isDark}"></v-select>
              <v-text-field v-else :loading="loading" v-model="metaData[field.label]" :counter="8"
                :rules="[rules.required]" :label="field.label" required></v-text-field>
            </v-col>
          </v-row>
        </v-container>


      </v-card-text>
      <v-card-actions>
        <div class="px-5 d-flex">
          <v-icon :color="$store.getters.getColorPalette().submitbtnColor">mdi-alert</v-icon><p class="pt-5 pl-2"> Note : Date and Time can't change.</p>
        </div>
        <v-spacer></v-spacer>
        <v-btn v-if="!loading" :color="$store.getters.getColorPalette().submitbtnColor" text @click="onSubmit">
     {{ $store.getters.getTextMap().re_write }}
        </v-btn>
        <v-btn v-if="!loading" :color="$store.getters.getColorPalette().submitbtnColor" text @click="onReset">
          {{ $store.getters.getTextMap().cancel }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
  import axios from 'axios'
  import moment from 'moment'
  import InfoAlert from '@/components/InfoAlert'
  import FormRules from '@/utillities/FormRules'
  export default {
    name: 'EditWrittenBatchLog',
    props: ['batchLog', 'selectedItem'],
    components: {
      InfoAlert
    },
    mounted() {
      this.init()
      if (this.selectedItem) {
        this.form.fromTime = this.Time(this.selectedItem.from_timestamp);
        this.form.toTime = this.Time(this.selectedItem.to_timestamp);
        this.form.date = this.Date(this.selectedItem.from_timestamp);
        this.form.to_date = this.Date(this.selectedItem.to_timestamp);
        this.form.primary_field_data = this.selectedItem.primary_field_data;

        let metaKeys = Object.keys(this.batchLog.meta)
        for (let key of metaKeys) {
          if (this.selectedItem[key]) {
            this.metaData[key] = this.selectedItem[key]
          }
        }



      }

    },
    data() {
      return {
        loading: false,
        info: '',
        show:false,
        showDismissibleAlert: false,
        valid: false,
        menu: null,
        menu1: null,
        menu2: null,
        menu3: null,
        metaData: {},
        meta: {},
        form: {
          fromTime: null,
          toTime: null,
          date: null,
          to_date: null
        },
        rules: FormRules.rules,
        objectRules: [
          FormRules.rules.required,
        ],
        floatRules: [
          // v => !!v || 'Required',
          v => (!v || !isNaN(Number(v))) || 'It should be a number',
          v => (!v || (v && v > 0)) || 'It should be more than zero'
        ],
        logRules: [
          v => !!v || 'Required',
          v => /\S+/.test(v) || 'Required',
          v => (v && v.length <= 100 && v.length > 0) || 'Log must be less than 100 characters',
        ],
      }
    },
    computed: {

      fromTimeStamp() {
        if (this.form.date) {

          let dt = this.form.date.toString()
          if (this.form.fromTime) {
            dt += ' ' + this.form.fromTime.toString()
            return Number(moment(dt, 'YYYY-MM-DD HH:mm').format("X"));
          }
          return Number(moment(dt, 'YYYY-MM-DD').format("X"));

        } else {
          return null
        }
      },
      toTimeStamp() {
        if (this.form.to_date) {
          let dt = this.form.to_date.toString()
          if (this.form.toTime) {
            dt += ' ' + this.form.toTime.toString()
            return Number(moment(dt, 'YYYY-MM-DD HH:mm').format("X"));
          }
          return Number(moment(dt, 'YYYY-MM-DD').format("X"));

        } else if (this.form.date) {

          let dt = this.form.date.toString()
          if (this.form.toTime) {
            dt += ' ' + this.form.toTime.toString()
            return Number(moment(dt, 'YYYY-MM-DD HH:mm').format("X"));
          }
          return Number(moment(dt, 'YYYY-MM-DD').format("X"));

        } else {
          return null
        }
      },

      
      metaItems() {
        if (this.meta) {
          return Object.values(this.meta)
        }
        return []
      }


    },
    methods: {
      init() {
        if (this.batchLog) {
          this.meta = Object.assign({}, this.batchLog.meta)
          // console.log(this.meta)
        }
      },

      Time(timestamp) {
        return moment(timestamp).format('HH:mm');
      },
      Date(timestamp) {
        return moment(timestamp).format('YYYY-MM-DD');
      },
      getListOptions(list_id) {
        let op = []
        let lst = this.$store.getters.getListDataById(list_id)
        for (let i of lst) {
          op.push({
            label: i.tag,
            value: i.value
          })
        }
        return op
      },
      onSubmit() {
        this.$refs.groupForm.validate()

        let data = {}
        if (this.valid) {
         

          this.loading = true
 

          data['log_id'] = this.batchLog.log_id
          data['from_timestamp'] = this.fromTimeStamp
          data['to_timestamp'] = this.toTimeStamp
          data['meta_data'] = this.metaData

          console.log(data);

          axios.post(this.$store.state.api + 'rewritebatch', data, {
            headers: {
              Authorization: 'Bearer ' + this.$store.state.jwt
            }
          })
            .then(response => {
              console.log(response);
              if (response.data.status === 'success') {                
                this.loading = false
                this.info = "Log Re-Written"
                this.showDismissibleAlert = true
                this.$store.dispatch('refreshParameters')
                this.$emit('update')
                this.$emit('close')

              } else {
                this.info = response.data.msg;
                this.showDismissibleAlert = true;
                this.loading = false
              }
            })
            .catch(err => {
              console.log(err)
              this.info = err
              this.loading = false
              this.showDismissibleAlert = true
            });
        }
      },
      onReset() {
        this.$emit('close')
        
      }
    },
    watch: {
      "batchLog.meta": {
        handler() {
          this.init()
        },
        deep: true
      },
    }
  }
</script>
<style scoped>
  table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;

  }
</style>