<template>
    <v-container class="" fluid>
        
        <span v-if="$store.state.batchProductionLogs && $store.state.batchProductionLogs.length>0">
            <v-subheader :style="{ color: $store.getters.getColorPalette().accentCode }">{{$store.getters.getTextMap().batch_production_logs}}</v-subheader><v-divider :color="$store.getters.getColorPalette().accentCode"></v-divider> 
        <v-row>
        <v-col  cols="4" v-bind:key="batchLog.log_id" v-for="batchLog in $store.state.batchProductionLogs">
            <BatchProductionLogger :batchLog="batchLog"/>
        </v-col>
        </v-row>
        </span>
    </v-container>
</template>

<script>
import BatchProductionLogger from '@/components/items/loggers/BatchProductionLogger'
export default {
    name:'BatchProductionLoggerList',
    data(){
        return {

        }
    },
    components:{
        BatchProductionLogger
    },
    computed:{
   
    },
    methods:{
    }
}
</script>

<style scoped>

</style>