<template>
    <v-container class="" fluid>
        <span>
            <v-subheader :style="{ color: $store.getters.getColorPalette().accentCode }">{{$store.getters.getTextMap().esg_data_logs}}</v-subheader><v-divider :color="$store.getters.getColorPalette().accentCode"></v-divider> 
        <v-row>
        <v-col  cols="4">
            <ESGDataLogger/>
        </v-col>
        </v-row>
        </span>
    </v-container>
</template>

<script>
import ESGDataLogger from '@/components/items/loggers/ESGDataLogger'
export default {
    name:'ESGDataLoggerList',
    data(){
        return {
        }
    },
    components:{
        ESGDataLogger
    },
}
</script>