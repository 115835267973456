<template>
    <v-form ref="groupForm" v-model="valid">
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert = false" :info="info" />
        <v-card class="pb-10 pt-2" :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
            <v-card-title class="headline">
                {{ $store.getters.getTextMap().write_in_logbook }}
            </v-card-title>
            <v-divider/>
            <v-row class="mx-2 mt-2">
                <v-col>
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-menu 
                        v-if="!loading" 
                        v-model="menu" 
                        :close-on-content-click="false" 
                        :nudge-right="40"
                        transition="scale-transition" 
                        offset-y 
                        min-width="290px">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field 
                                v-model="form.date" 
                                :label="$store.getters.getTextMap().date" 
                                prepend-icon="mdi-calendar" 
                                readonly
                                :rules="[rules.required]" 
                                outlined dense v-bind="attrs" v-on="on" >
                            </v-text-field>
                        </template>
                        <v-date-picker v-model="form.date" @input="menu = false"></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col>
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-menu 
                        v-if="!loading" 
                        ref="menufrom" 
                        v-model="menu3" 
                        :close-on-content-click="false"
                        :nudge-right="40" 
                        :return-value.sync="form.fromTime" 
                        transition="scale-transition"
                        offset-y max-width="290px" 
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field 
                                v-model="form.fromTime" 
                                :label="$store.getters.getTextMap().production_start_timestamp"
                                prepend-icon="mdi-clock-time-four-outline" 
                                readonly v-bind="attrs" 
                                v-on="on"
                                :rules="[rules.required]" 
                                outlined dense
                            >
                            </v-text-field>
                        </template>
                        <v-time-picker 
                            v-if="menu3" 
                            v-model="form.fromTime" 
                            full-width format="24hr"
                            @click:minute="$refs.menufrom.save(form.fromTime)">
                        </v-time-picker>
                    </v-menu>
                </v-col>
                <v-col>
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-menu 
                        v-if="!loading" 
                        ref="menuto" 
                        v-model="menu2" 
                        :close-on-content-click="false"
                        :nudge-right="40" 
                        :return-value.sync="form.toTime" 
                        transition="scale-transition"
                        offset-y 
                        max-width="290px" 
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field 
                                v-model="form.toTime" 
                                :label="$store.getters.getTextMap().production_end_timestamp" 
                                prepend-icon="mdi-clock-time-four-outline"
                                :rules="[rules.required]"  
                                readonly 
                                v-bind="attrs" 
                                v-on="on" 
                                outlined 
                                dense
                            ></v-text-field>
                        </template>
                        <v-time-picker 
                            v-if="menu2" 
                            v-model="form.toTime" 
                            full-width format="24hr"
                            @click:minute="$refs.menuto.save(form.toTime)">
                        </v-time-picker>
                    </v-menu>
                </v-col>
            </v-row>
            <v-divider/>
            <v-row class="mx-2 mt-2">
                <v-col>
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-autocomplete
                        v-if="!loading"
                        v-model="form.from_time"
                        :items="shiftOptions"
                        :label="$store.getters.getTextMap().shifts"
                        outlined   
                        dense       
                        item-text="label"
                        item-value="value"
                        required
                        :dark="$store.getters.getColorPalette().isDark"
                        :rules="objectRules"
                    ></v-autocomplete>
                </v-col>
                <v-col>
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-autocomplete
                        v-if="!loading"
                        v-model="form.machine_id"
                        :items="machineOptions"
                        :label="$store.getters.getTextMap().machines"
                        outlined   
                        dense       
                        item-text="label"
                        item-value="value"
                        required
                        :dark="$store.getters.getColorPalette().isDark"
                        :rules="objectRules"
                    ></v-autocomplete>
                </v-col>
                <v-col>
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-autocomplete
                        v-if="!loading"
                        v-model="form.sku_id"
                        :items="skuOptions"
                        :label="$store.getters.getTextMap().sku"
                        outlined   
                        dense       
                        item-text="label"
                        item-value="value"
                        required
                        :dark="$store.getters.getColorPalette().isDark"
                        :rules="objectRules"
                    ></v-autocomplete>
                </v-col>
            </v-row>
            <v-row class="mx-2">
                <v-col>
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-text-field 
                        outlined 
                        dense 
                        v-if="!loading" 
                        v-model="form.total_production" 
                        :label="$store.getters.getTextMap().production"
                        :rules="[rules.required, rules.unum, rules.noSpace]">
                    </v-text-field>
                </v-col>
                <v-col>
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-text-field 
                        outlined 
                        dense 
                        v-if="!loading" 
                        v-model="form.rejected" 
                        :label="$store.getters.getTextMap().rejected"
                        :rules="[rules.required, rules.unum, rules.noSpace]">
                    </v-text-field>
                </v-col>
                <v-col>
                    <v-text-field 
                        outlined 
                        dense 
                        v-if="!loading"
                        :counter="100"  
                        v-model="form.machine_operator" 
                        :label="$store.getters.getTextMap().machine_operator"
                        :rules="nameRules">
                    </v-text-field>
                </v-col>
            </v-row>
            <v-divider/>
            <v-row no-gutters>
                <v-col cols="auto" class="d-flex" align="right" align-self="center">
                    <v-card-title v-if="inMinutes" class="sub-heading">{{$store.getters.getTextMap().enter_data_in_minutes}}</v-card-title>
                    <v-card-title v-else class="sub-heading">{{$store.getters.getTextMap().enter_data_in_hours}}</v-card-title>
                    <v-spacer/>
                    <v-switch
                        small
                        inset
                        style="transform: scale(0.8);"
                        color="success"
                        v-model="inMinutes"
                        :label="$store.getters.getTextMap().enter_in_minutes"
                    ></v-switch>
                </v-col>
            </v-row>
            <v-row v-if="inMinutes" class="mx-4" no-gutters>
                <v-col>
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-text-field 
                        outlined 
                        dense 
                        v-if="!loading" 
                        v-model="form.logged_running" 
                        :label="$store.getters.getTextMap().running_time"
                        :rules="[rules.required, rules.unum, rules.noSpace]">
                    </v-text-field>
                </v-col>
                <v-col class="mx-8">
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-text-field 
                        outlined 
                        dense 
                        v-if="!loading" 
                        v-model="form.logged_idle" 
                        :label="$store.getters.getTextMap().idle_time"
                        :rules="[rules.required, rules.unum, rules.noSpace]">
                    </v-text-field>
                </v-col>
                <v-col>
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-text-field 
                        outlined 
                        dense 
                        v-if="!loading" 
                        v-model="form.logged_downtime" 
                        :label="$store.getters.getTextMap().down_time"
                        :rules="[rules.required, rules.unum, rules.noSpace]">
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row class="mx-2" no-gutters v-else>
                <v-col>
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-menu 
                        v-if="!loading" 
                        ref="menuRunning" 
                        v-model="menu4" 
                        :close-on-content-click="false"
                        :nudge-right="40" 
                        :return-value.sync="logged_running_hour" 
                        transition="scale-transition"
                        offset-y max-width="290px" 
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field 
                                v-model="logged_running_hour" 
                                :label="$store.getters.getTextMap().running_time"
                                prepend-icon="mdi-clock-time-four-outline" 
                                readonly v-bind="attrs" 
                                v-on="on"
                                :rules="[rules.required]" 
                                outlined dense
                            >
                            </v-text-field>
                        </template>
                        <v-time-picker 
                            v-if="menu4" 
                            v-model="logged_running_hour" 
                            full-width format="24hr"
                            @click:minute="$refs.menuRunning.save(logged_running_hour)">
                        </v-time-picker>
                    </v-menu>
                </v-col>
                <v-col class="mx-2">
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-menu 
                        v-if="!loading" 
                        ref="menuIdle" 
                        v-model="menu5" 
                        :close-on-content-click="false"
                        :nudge-right="40" 
                        :return-value.sync="logged_idle_hour" 
                        transition="scale-transition"
                        offset-y max-width="290px" 
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field 
                                v-model="logged_idle_hour" 
                                :label="$store.getters.getTextMap().idle_time"
                                prepend-icon="mdi-clock-time-four-outline" 
                                readonly v-bind="attrs" 
                                v-on="on"
                                :rules="[rules.required]" 
                                outlined dense
                            >
                            </v-text-field>
                        </template>
                        <v-time-picker 
                            v-if="menu5" 
                            v-model="logged_idle_hour" 
                            full-width format="24hr"
                            @click:minute="$refs.menuIdle.save(logged_idle_hour)">
                        </v-time-picker>
                    </v-menu>
                </v-col>
                <v-col>
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-menu 
                        v-if="!loading" 
                        ref="menuDowntime" 
                        v-model="menu6" 
                        :close-on-content-click="false"
                        :nudge-right="40" 
                        :return-value.sync="logged_downtime_hour" 
                        transition="scale-transition"
                        offset-y max-width="290px" 
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field 
                                v-model="logged_downtime_hour" 
                                :label="$store.getters.getTextMap().down_time"
                                prepend-icon="mdi-clock-time-four-outline" 
                                readonly v-bind="attrs" 
                                v-on="on"
                                :rules="[rules.required]" 
                                outlined dense
                            >
                            </v-text-field>
                        </template>
                        <v-time-picker 
                            v-if="menu6" 
                            v-model="logged_downtime_hour" 
                            full-width format="24hr"
                            @click:minute="$refs.menuDowntime.save(logged_downtime_hour)">
                        </v-time-picker>
                    </v-menu>
                </v-col>
            </v-row>
            <v-divider/>
            <v-card-title class="sub-heading">{{$store.getters.getTextMap().custome_fields}}</v-card-title>
            <v-row class="mx-2">
                <v-col v-for="field in metaItems" :key="field.label">
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-select 
                        v-if="!loading && field.type == 'list'" 
                        v-model="metaData[field.label]"
                        :items="getListOptions(field.field)" 
                        :label="field.label" 
                        dense 
                        outlined 
                        item-text="label"
                        item-value="value" 
                        required :rules="objectRules"
                        :menu-props="{ dark: $store.getters.getColorPalette().isDark }">
                    </v-select>
                    <v-text-field 
                        v-else 
                        :loading="loading" 
                        outlined dense 
                        v-model="metaData[field.label]"
                        :counter="50" 
                        :rules="[rules.required,rules.size(50)]" 
                        :label="field.label" 
                        required>
                    </v-text-field>
                </v-col>
            </v-row>
            <v-divider/>
            <v-row class="mt-2">
                <v-spacer/>
                <v-col align="right">
                    <v-btn 
                        small 
                        @click="onSubmit" :dark="$store.getters.getColorPalette().isDark"
                        :color="$store.getters.getColorPalette().submitbtnColor" 
                        v-if="!loading"
                    >
                        {{$store.getters.getTextMap().submit}}<v-icon small class="pl-2">mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn 
                        small 
                        @click="onReset" 
                        :dark="$store.getters.getColorPalette().isDark"
                        :color="$store.getters.getColorPalette().cancelbtnColor"
                        class="mx-2" 
                    >
                        {{$store.getters.getTextMap().clear}}<v-icon small class="pl-2">mdi-eraser</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-card>
    </v-form>
</template>
<script>
import axios from 'axios'
import InfoAlert from '@/components/InfoAlert'
import FormRules from '@/utillities/FormRules'
import moment from 'moment'
export default {
    name: 'WriteShiftMachineUsageLog',
    props: {
        'shiftMachineUsageLog': {
            type: Object,
            required: true
        }
    },
    components: {
        InfoAlert
    },
    data() {
        return {
            loading: false,
            info: '',
            showDismissibleAlert: false,
            showSKUID:false,
            valid: false,
            menu: null,
            menu1: null,
            menu2: null,
            menu3: null,
            menu4: null,
            menu5: null,
            menu6: null,
            metaData: {},
            meta: {},
            machineOptions:[],
            logged_running_hour:null,
            logged_idle_hour:null,
            logged_downtime_hour:null,
            inMinutes:false,
            form: {
                production: 0,
                rejected: 0,
                machine_operator: null,
                machine_id: null,
                from_time: null,
                date: null,
            },
            rules: FormRules.rules,
            objectRules: [
                FormRules.rules.required,
            ],
            floatRules: [
                v => (!v || !isNaN(Number(v))) || 'It should be a number',
                v => (!v || (v && v > 0)) || 'It should be more than zero'
            ],
            logRules: [
                v => !!v || 'Required',
                v => /\S+/.test(v) || 'Required',
                v => (v && v.length <= 100 && v.length > 0) || 'Log must be less than 100 characters',
            ],
            numberRules: [
                v => !!v || 'Required',
                v => /^\d+$/.test(v) || 'Input must contain only numbers'
            ],
            nameRules: [
                v => !!v || 'Name is required',
                v => /\S+/.test(v) || 'Name is required',
                v => (v && v.length <= 100 && v.length > 0) || 'Name must be less than 100 characters',
                v => isNaN(parseFloat(v)) || 'Input cannot be a number' // New rule for checking if the input is not a number
            ],
        }
    },
    mounted() {
        this.init()
    },
    computed: {
        skuOptions(){
            let op=[]
            let g=this.$store.state.skus
            for(let i of g){
                if(this.showSKUID){
                    op.push( {value:i.sku_id, label: i.sku_id })
                }else{
                    op.push( {value:i.sku_id, label: i.sku_name })
                }
            }
          return op
        },
        shiftOptions(){
            let op=[]
            let g=this.$store.getters.getShiftDataById(this.shiftMachineUsageLog.shift_id)
            for(let i of g){
                op.push( {value:i.from_time, label: i.name })
            }
          return op
        },
        metaItems() {
            if (this.meta) {
                return Object.values(this.meta)
            }
            return []
        },
        fromTimeStamp() {
            if (this.form.date) {
                let temp=null
                let dt = this.form.date.toString()
                if (this.form.fromTime) {
                    dt += ' ' + this.form.fromTime.toString()
                    temp=Number(moment(dt, 'YYYY-MM-DD HH:mm').format("X"))
                    return temp;
                }
                temp=Number(moment(dt, 'YYYY-MM-DD').format("X"));
                
                return temp;

            } else {
                return null
            }
        },
        toTimeStamp() {
            let temp=null
            if (this.form.to_date) {
                let dt = this.form.to_date.toString()
                if (this.form.toTime) {
                    dt += ' ' + this.form.toTime.toString()
                    temp=Number(moment(dt, 'YYYY-MM-DD HH:mm').format("X"))
                    if(this.fromTimeStamp && this.fromTimeStamp>=temp){
                        temp=temp+86400
                    }
                    return temp;
                }
                temp=Number(moment(dt, 'YYYY-MM-DD').format("X"))
                if(this.fromTimeStamp && this.fromTimeStamp>=temp){
                    temp=temp+86400
                }
                return temp;

            } else if (this.form.date) {

                let dt = this.form.date.toString()
                if (this.form.toTime) {
                    dt += ' ' + this.form.toTime.toString()
                    temp=Number(moment(dt, 'YYYY-MM-DD HH:mm').format("X"))
                    if(this.fromTimeStamp && this.fromTimeStamp>=temp){
                        temp=temp+86400
                    }
                    return temp;
                }
                temp=Number(moment(dt, 'YYYY-MM-DD').format("X"))
                if(this.fromTimeStamp && this.fromTimeStamp>=temp){
                    temp=temp+86400
                }
                return temp;

            } else {
                return null
            }
        },
    },
    methods: {
        init() {
            if (this.shiftMachineUsageLog && this.shiftMachineUsageLog.shift_id) {
                this.getMachinesForShift()
                if( this.shiftMachineUsageLog.meta){
                    this.meta = Object.assign({}, this.shiftMachineUsageLog.meta)
                }
            }
        },
        convertToMinutes(time,column) {
            if (time) {
                let temp=String(time)
                const [hours, minutes] = temp.split(':').map(Number);
                const totalMinutes = (hours * 60) + minutes;
                switch(column){
                    case 'logged_running':
                        this.form.logged_running = totalMinutes
                        break
                    case 'logged_idle':
                        this.form.logged_idle = totalMinutes
                        break
                    case 'logged_downtime':
                        this.form.logged_downtime = totalMinutes
                        break
                }
            }
        },
        getMachinesForShift(){
            let op=[]
            this.loading=true
            axios.post(this.$store.state.api + 'getMachinesByShift', {shift_id:this.shiftMachineUsageLog.shift_id}, {headers: {Authorization: 'Bearer ' + this.$store.state.jwt}})
                .then(response => {
                    if (response.data.status === 'success') {
                        this.loading = false
                        for(let i of response.data.data){
                            op.push( {value:i.machine_id, label: i.name })
                        }
                    } else {
                        this.info = response.data.msg;
                        this.showDismissibleAlert = true;
                        this.loading = false
                    }
                })
                .catch(err => {
                    console.log(err)
                    this.info = err
                    this.loading = false
                    this.showDismissibleAlert = true
                });
            this.machineOptions=op
        },
        getListOptions(list_id) {
            let op = []
            let lst = this.$store.getters.getListDataById(list_id)
            for (let i of lst) {
                op.push({
                    label: i.tag,
                    value: i.value
                })
            }
            return op
        },
        onSubmit() {
            this.$refs.groupForm.validate()
            if (this.valid) {
                this.loading = true
                this.form.shift_id = this.shiftMachineUsageLog.shift_id
                this.form.meta_data = this.metaData
                this.form['production_start']= this.fromTimeStamp,
                this.form['production_end']= this.toTimeStamp,
                axios.post(this.$store.state.api + 'writeShiftMachineUsageLog', this.form, {headers: {Authorization: 'Bearer ' + this.$store.state.jwt}})
                .then(response => {
                    if (response.data.status === 'success') {
                        this.loading = false
                        this.$refs.groupForm.reset()
                        this.form={}
                        this.$emit('success')
                        this.$emit('close')
                    } else {
                        this.info = response.data.msg;
                        this.showDismissibleAlert = true;
                        this.loading = false
                    }
                })
                .catch(err => {
                    console.log(err)
                    this.info = err
                    this.loading = false
                    this.showDismissibleAlert = true
                });
            }
        },
        onReset() {
            this.$refs.groupForm.reset()
            this.$emit('close')
        }
    },
    watch: {
        "shiftMachineUsageLog.meta": {
            handler() {
                this.init()
            },
            deep: true
        },
        logged_running_hour(){
            this.convertToMinutes(this.logged_running_hour,'logged_running')
        },
        logged_idle_hour(){
            this.convertToMinutes(this.logged_idle_hour,'logged_idle')
        },
        logged_downtime_hour(){
            this.convertToMinutes(this.logged_downtime_hour,'logged_downtime')
        },
    }
}
</script>