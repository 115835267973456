<template>
    <v-container fluid>
        <v-card :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}" flat  :dark="$store.getters.getColorPalette().isDark">
            <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
            <v-card class="pa-1" flat :dark="$store.getters.getColorPalette().isDark" :color=" $store.getters.getColorPalette().background2ColorCode">
                <v-card-title>{{$store.getters.getTextMap().log_details}}
</v-card-title>
                <v-row style="margin-left:20px;">
                    <v-col>
                        <v-menu
                            v-model="menu1"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                            :dark="$store.getters.getColorPalette().isDark"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="fromTime"
                                    :label="$store.getters.getTextMap().from_date"

                                    prepend-icon="mdi-calendar"
                                    readonly
                                    :loading="loading"
                                    v-bind="attrs"
                                    v-on="on"
                                    :rules="ISODateRules"
                                    :dark="$store.getters.getColorPalette().isDark"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="fromTime"
                                @input="menu1 = false"
                                :dark="$store.getters.getColorPalette().isDark"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col>
                        <v-menu
                            v-model="menu2"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                            :dark="$store.getters.getColorPalette().isDark"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="toTime"
                                    :label="$store.getters.getTextMap().to_date"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    :loading="loading"
                                    v-bind="attrs"
                                    v-on="on"
                                    :rules="ISODateRules"
                                    :dark="$store.getters.getColorPalette().isDark"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="toTime"
                                @input="menu2 = false"
                                :dark="$store.getters.getColorPalette().isDark"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col style="margin-top:2%">
                        <v-btn  class="mx-2" small outlined v-if="!loading"  :color="$store.getters.getColorPalette().submitbtnColor" @click="getData">
                            {{$store.getters.getTextMap().view}}
                        </v-btn>
                    </v-col>
                </v-row>
                <v-data-table
                    :headers="headers"
                    :items="dbData"
                    item-key="index"
                    :dark="$store.getters.getColorPalette().isDark"
                >
                </v-data-table>
            </v-card>
        </v-card>
    </v-container>
</template>

<script>
import axios from 'axios'
import InfoAlert from '@/components/InfoAlert'
import moment from 'moment'
export default {
    name:'ESGDataLogDetails',
    components:{
        InfoAlert,
    },
    mounted(){
        this.fromTime=moment().subtract(180,'d').format('YYYY-MM-DD')
        this.toTime=moment().format('YYYY-MM-DD')
        this.getData()
    },
    data(){
        return {
            search:'',
            loading:false,
            expanded: [],
            info:'',
            menu:false,
            menu1:false,
            menu2:false,
            menu3:false,
            toTime:null,
            fromTime:null,
            showDismissibleAlert:false,
            dbData:[],
            headers: [
                {
                    text: 'From Date',
                    align: 'start',
                    filterable: false,
                    value: 'from_date',
                    class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName
                },
                {
                    text: 'To Date',
                    align: 'start',
                    filterable: false,
                    value: 'date',
                    class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName
                },
                {
                    text: 'Scope',
                    value: 'scope',
                    class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName
                },
                { 
                    text: 'Source', 
                    value: 'source', 
                    class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName
                },
                { 
                    text: 'Source Type', 
                    value: 'type', 
                    class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName
                },
                { 
                    text: 'Name', 
                    value: 'name', 
                    class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName 
                },
                {
                    text: 'UOM', 
                    value: 'uom', 
                    class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName 
                },
                {
                    text: 'Data', 
                    value: 'data', 
                    class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName 
                },
                {
                    text: 'kgCO2', 
                    value: 'tco2', 
                    class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName 
                },
                {
                    text: 'Logged By', 
                    value: 'logged_by', 
                    class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName 
                },
            ],
            ISODateRules:[
                v => !!v || 'Required',
                v => /[0-9][0-9][0-9][0-9]-[0-9][0-9]-[0-9][0-9]$/.test(v) || 'It should be a Date',
            ]
        }
    },
    methods:{
        getData(){
            this.loading=true
            let payload={
                from_date:this.fromTime,
                to_date:this.toTime
            }
            axios.post(this.$store.state.api+'getESGScopeDataBetweenDate',payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response=>{
                if(response.data.status=='success'){
                    this.dbData=response.data.data
                    for(let i in this.dbData){
                       this.dbData[i]['index']=i
                    }
                }else{
                    this.info=response.data.msg
                    this.showDismissibleAlert=true
                }
                this.loading=false
            }).catch(err=>{
                this.loading=false
                this.info=err
                this.showDismissibleAlert=true   
            })
        }
    },
}
</script>
