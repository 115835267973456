<template>
  <v-lazy v-model="isActive" :options="{
    threshold: .5
  }" transition="fade-transition">
    <div :color="$store.getters.getColorPalette().accentCode">
      <v-card :dark="$store.getters.getColorPalette().isDark" flat @click="dialog = true" class="px-2 py-0 mx-1 my-2 "
        :color="$store.getters.getColorPalette().loggerColor">

        <v-card-title> {{ batchLog.name }} <v-spacer></v-spacer><v-icon color="orange lighten-1">
            mdi-clipboard-list-outline</v-icon>
        </v-card-title>
      </v-card>

      <v-dialog v-model="dialog" fullscreen hide-overlay transition="scroll-x-transition" >
        <v-card :dark="$store.getters.getColorPalette().isDark"
          :color="$store.getters.getColorPalette().backgroundColorCode">
          <v-toolbar :dark="$store.getters.getColorPalette().isDark"
            :color="$store.getters.getColorPalette().foregroundColorCode">
            <v-btn icon :dark="$store.getters.getColorPalette().isDark" @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title class="headline">{{ batchLog.name }} {{$store.getters.getTextMap().logs}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn :dark="$store.getters.getColorPalette().isDark" text @click="dialog = false">
                {{$store.getters.getTextMap().close}}
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <BatchProductionLogDetails :batchLog="batchLog">
            <template v-slot:top>
              <WriteBatchProductionLog :batchLog="batchLog" />
            </template>
          </BatchProductionLogDetails>
        </v-card>
      </v-dialog>
    </div>
  </v-lazy>
</template>
<script>

import BatchProductionLogDetails from '@/components/panels/loggers/BatchProductionLogDetails'
import WriteBatchProductionLog from '@/components/crud_components/loggers/WriteBatchProductionLog'

export default {
  name: 'DynamicLogger',
  props: {
    'batchLog': {
      type: Object,
      required: true
    }
  },
  components: {
    BatchProductionLogDetails,
    WriteBatchProductionLog
  },
  mounted() {
  },
  data() {
    return {
      isActive: false,
      dialog: false,


    }
  },
  computed: {

  },
  methods: {

  },
}
</script>