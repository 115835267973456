<template>
    <v-container fluid>
        <span v-if="$store.state.shiftMachineUsageLogs && $store.state.shiftMachineUsageLogs.length>0">
            <v-subheader :style="{ color: $store.getters.getColorPalette().accentCode }">{{$store.getters.getTextMap().shift_based_machine_usage_log}}</v-subheader><v-divider :color="$store.getters.getColorPalette().accentCode"></v-divider> 
            <v-row>
                <v-col  cols="4" v-bind:key="shiftMachineUsageLog.shift_id" v-for="shiftMachineUsageLog in $store.state.shiftMachineUsageLogs">
                    <ShiftMachineUsageLoggerItem :shiftMachineUsageLog="shiftMachineUsageLog"/>
                </v-col>
            </v-row>
        </span>
    </v-container>
</template>
<script>
import ShiftMachineUsageLoggerItem from '@/components/items/loggers/ShiftMachineUsageLoggerItem'
export default {
    name:'ShiftMachineUsageLogger',
    components:{
        ShiftMachineUsageLoggerItem
    }
}
</script>