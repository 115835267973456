<template>
    <v-container fluid :color="$store.getters.getColorPalette().backgroundColorCode">
      <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-row>
            <v-col>
                <v-spacer></v-spacer>
                <v-col >
                    <span>
                        <v-btn text outlined :color="$store.getters.getColorPalette().submitbtnColor" @click="goToDetailsTablePage">
                            {{$store.getters.getTextMap().check_logs}}
                        <v-icon small class="mr-2" @click="editDialog=!editDialog" v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess','betaaccess'])" :color="$store.getters.getColorPalette().accentCode">
                                mdi-book-open-page-variant-outline
                        </v-icon>
                        </v-btn>
                    </span>
                </v-col>
            </v-col>
            <v-col align="right">
                <v-spacer></v-spacer>
                <v-col  v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])">
                    <span v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])">
                        <v-btn text class="mx-1" outlined :color="$store.getters.getColorPalette().accentCode" @click="editDialog=!editDialog" v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess','betaaccess'])">{{$store.getters.getTextMap().edit}}
                            <v-icon small class="mr-2">
                                mdi-pencil
                            </v-icon>
                        </v-btn>
                        <DeleteConfirmation  v-on:confirm="deleteDataLog()"  title="Delete Confirmation"  description="Are you sure you want to delete this logger?">
                            <v-btn text outlined :color="$store.getters.getColorPalette().alertColor">{{$store.getters.getTextMap().delete}}
                                <v-icon small :color="$store.getters.getColorPalette().deletebtnColor">
                                    mdi-delete
                                </v-icon>
                            </v-btn>
                        </DeleteConfirmation>
                    </span>
                </v-col>
            </v-col>
        </v-row>
        <v-row v-if="editDialog">
            <v-col cols="12"><CreateDataLog :dataLog="logger" v-on:close="close"/></v-col>
        </v-row>
        <v-row v-if="paramDetailsList.length>0">
            <WriteDataLog :dataLog="logger" v-on:close="close"/>
        </v-row>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import axios from 'axios'
import DeleteConfirmation from '@/components/DeleteConfirmation'
import CreateDataLog from '@/components/crud_components/CreateDataLog'
import WriteDataLog from '@/components/crud_components/WriteDataLog'

export default {
name:'DataLogDetails',
props:['dataLog'],
components:{
    InfoAlert,
    DeleteConfirmation,
    CreateDataLog,
    WriteDataLog,
},
mounted() {
    if(this.dataLog && this.dataLog.log_id){
        this.fetchDataLoggerListDetails()
    }
},
data(){
    return {
        showDialog:false,
        valid:false,
        showDismissibleAlert:false,
        loading:false,
        info:'',
        editDialog:false,
        paramDetailsList:[],
        isActive:false,
        menu:false,
        logger:{}
    }
},
computed:{
},
methods: {
    deleteDataLog(){
        axios.post(this.$store.state.api+'deleteDataLogger',this.dataLog,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
        .then(response=>{
            if(response.data.status==='success'){
            this.info = 'Logger Deleted successfully';
            this.showDismissibleAlert = true;
            this.$store.dispatch('refreshDataLog')
            }else{
            this.info = response.data.msg;
            this.showDismissibleAlert = true;
            }
            this.loading=false
        }).catch(err=>{
            console.log(err)
            this.showDismissibleAlert=true
            this.info=err
            this.loading=false
        })
    },
    fetchDataLoggerListDetails(){
        let payload = {
            log_id:this.dataLog.log_id
        }
        axios.post(this.$store.state.api+'getDataLoggerList',payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
        .then(response=>{
            if(response.data.status==='success'){
                this.paramDetailsList = response.data.data
                // this.dataLog.paramDetailsList = this.paramDetailsList
            }else{
            this.info = response.data.msg;
            this.showDismissibleAlert = true;
            }
            this.loading=false
        }).catch(err=>{
            console.log(err)
            this.showDismissibleAlert=true
            this.info=err
            this.loading=false
        })
    },
    goToDetailsTablePage(){
        this.$router.push(`/datalogdetailtable/${this.dataLog.log_id}`);
    },
    close(){
            this.loading=false
            this.editDialog=false
            this.$emit('close')
        },
},
watch:{
    paramDetailsList:{
        handler(){
                
                this.logger=Object.assign({},this.dataLog)
                this.logger.paramDetailsList=this.paramDetailsList
            },
            deep:true
    }
}
}
</script>