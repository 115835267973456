<template>
    <v-container >
        <!-- <v-row>
                <v-col cols="12">
                    <WriteLog />
                </v-col>
            </v-row> -->
            <v-row>
                <v-col cols="12">
                    <LogTable />
                </v-col>
            </v-row>
    </v-container>
    
</template>
<script>
// import WriteLog from '@/components/crud_components/WriteLog'
import LogTable from '@/components/lists/LogTable'
export default {
    name:'IndustryLogPanel',
    components:{
        // WriteLog,
        LogTable
    },
    data(){
        return {
            
            search:'',
            loading:false,
            expanded: [],
            logs:[],
            headers: [
          {
            text: 'timestamp',
            align: 'start',
            filterable: false,
            value: 'action_id',
          },
          { text: 'Log', value: 'msg' },
          { text: 'Type', value: 'type' },
          
          
        ],
        }
    },
}
</script>