<template>
    <v-container class="" fluid>
        <span v-if="$store.state.dataLogs && $store.state.dataLogs.length>0">
            <v-subheader :style="{ color: $store.getters.getColorPalette().accentCode }">{{$store.getters.getTextMap().data_logs}}</v-subheader><v-divider :color="$store.getters.getColorPalette().accentCode"></v-divider> 
        <v-row>
        <v-col  cols="4" v-bind:key="dataLog.log_id" v-for="dataLog in $store.state.dataLogs">
            <DataLogger  :dataLog="dataLog"/>
        </v-col>
        </v-row>
        </span>
    </v-container>
</template>

<script>
import DataLogger from '@/components/items/DataLogger'
export default {
    name:'DataLoggerList',
    data(){
        return {

            showDismissibleAlert:false,
        info:'You do not have access for deleting a node from workspace'
        }
    },
    components:{
        DataLogger
    },
    computed:{
   
    },
    methods:{
        // deleteNode(node){
        //     //console.log(this.$store.state.user.user_level)
        //    /*if(this.$store.state.user.user_level>10){
        //        this.$store.dispatch('deleteNode',node)
        //    }else{
        //        this.showDismissibleAlert=true
        //    }*/
        //    this.$emit('deleteNode',node)
           
        // }
    }
}
</script>

<style scoped>

</style>