<template>
    <div class="text-center"  :dark="$store.getters.getColorPalette().isDark" >
        <v-btn
        :background-color="$store.getters.getColorPalette().background1ColorCode"
        :color="$store.getters.getColorPalette().accentCode"
        :dark="$store.getters.getColorPalette().isDark"
        small
        outlined 
        text
        @click="dialog = !dialog"
        >
      {{ $store.getters.getTextMap().add_esg_data }}<v-icon right :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().btnborderColorCode">mdi-clipboard-text-outline</v-icon>
        </v-btn>
        <v-dialog fullscreen hide-overlay transition="dialog-bottom-transition" v-model="dialog">
            <v-card  :dark="$store.getters.getColorPalette().isDark">
                <v-toolbar :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().foregroundColorCode">
                    <v-btn :dark="$store.getters.getColorPalette().isDark" @click="dialog = false" icon><v-icon>mdi-close</v-icon></v-btn>
                    <v-toolbar-title> {{ $store.getters.getTextMap().add_esg_data_log }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn text @click="dialog = false" :dark="$store.getters.getColorPalette().isDark">  {{ $store.getters.getTextMap().close }}</v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <AddESGDataLog v-on:close="dialog=false"/>
            </v-card>
      </v-dialog>
    </div>
</template>
<script>
import AddESGDataLog from '@/components/crud_components/loggers/AddESGDataLog'
export default {
    name:'AddESGDataLogBottomSheet',
    components:{
        AddESGDataLog  
    },
    data(){
        return {
            dialog:false
        }
    },
  }
  </script>
